import { trakerServiceAppCues } from '../../utils/appcues-util';
import moment from 'moment';
/** This function allows to create an identical object but with a new memory instance */
import cloneDeep from 'lodash/cloneDeep';

/** Services for work */
import {
  activityService,
  cncTypeService,
  userService,
  cncService,
  weekCommitmentService,
  projectService,
  projectServiceNew,
  taskCommitmentService,
  taskService,
  sectorService
} from '../../services';
import {
  calculateExpectedCost,
  calculateExpectedForWeek,
  getTask
} from '../../utils/lookahead-common';
import { ganttAPI } from '../../utils/customGanttPlugin';
import { getEnvironment, getYearFromThursday } from '../../utils';
import { base } from '../../services/base';
import {
  totangoEventTracking,
  totangoSetAccountAttributes
} from '../../analytics/implements/totango';
import { getCurrentCompany, getSignedUser } from '../../utils/userUtils';

/** querys */
export const getListCncs = async (sectorId) => {
  /** get cncs bysector */
  const listCncs = await cncService.showBySector(sectorId);
  return listCncs;
};

export const getCncTypes = async (project) => {
  /** get cnc Types by project */
  const getCncTypes = await cncTypeService.showByProject(project.id);
  return getCncTypes;
};

export const getResponsables = async (sector) => {
  /** get users of sector */
  const resp = await await userService.getBySector(sector.id);
  return resp;
};

export const getWeekCommitments = async (sectorId) => {
  /** get week commitments bysector */
  const resp = await weekCommitmentService.searchBySector(sectorId);
  return resp;
};

export const getLastLevelActivities = async (sector) => {
  const data = {
    sector_id: sector.id,
    start: '',
    end: '',
    ignore_dates: true
  };
  const resp = await activityService.getLookahead(data);
  return resp;
};

export const getAnalitycsByProject = async (projectId, ourRequest = null) => {
  /** get analitycs by project */
  const ret = await projectServiceNew.showAnalitic(projectId, ourRequest);
  return ret;
};

export const getAnalitycsByProjectBack = async (projectId, ourRequest) => {
  /** get analitycs by project */
  const ret = await projectService.showAnalitic(projectId, ourRequest);
  return ret;
};

/** mutations */
export const createCnc = async (data) => {
  const created = await cncService.createWithTasks(data);
  return created;
};

export const deleteCnc = async ({ cncId }) => {
  const ret = await cncService.destroy(cncId);
  return ret;
};

export const updateCnc = async ({ cnc }) => {
  const ret = await cncService.update(cnc);
  return ret;
};

/**
 * This function asks if a week is committed
 * @param {*} week week to search
 * @param {*} year year to search
 * @param {*} sector sector to search
 * @returns
 */
export const checkIfExistsWeeklyPlan = async (week, year, sector) => {
  if (!week) return false;
  const data = {
    week: parseInt(week),
    sectorId: parseInt(sector),
    year: year
  };
  const have = await weekCommitmentService.searchbyweek(data);

  let ret = false;
  if (have.commitment && have.commitment.id) {
    ret = have.commitment;
  }
  return ret;
};

const notifyEmailCommmitment = async (
  sectorId,
  projectId,
  currentWeek,
  dateRange
) => {
  const company = getCurrentCompany();
  const userLang = navigator.language || navigator.userLanguage;
  const users = await userService.getByProjectThrough(projectId);
  if (users.users) {
    const selectUsers = users.users.filter(
      (us) => us.is_active && us.userproject.report_tasks
    );
    const stageSelected = await sectorService.show(sectorId);
    const projectSelected = await projectService.show(projectId);
    if (selectUsers.length && company) {
      const data = {
        users: selectUsers,
        endDate: String(dateRange.end),
        week: currentWeek,
        stageName: stageSelected.sector.name || '',
        projectName: projectSelected.project.name || '',
        link:
          base.front +
          `${company.id}/project/${projectId}/schedule/${sectorId}/export/weekly/?type=commitment&planificationDay=${projectSelected.project.planification_day}&week=${currentWeek}`,
        lang: userLang.includes('es') ? 'es' : 'en',
        projectId,
        sectorId
      };
      await userService.notifyCommitment(data);
    }
  }
};

/** handle save weekly Plan */
/**
 * This function saves a weekly plan
 * @param {*} tasksWeekly Array of tasks to save weekly plan
 * @param {*} currentWeek Number of week
 * @param {*} dateRange Range (start and end is needed)
 * @param {*} project project id
 * @param {*} sector sector id
 * @returns Object weekcommitment saved
 */

export const saveWeeklyPlan = async (
  tasksWeekly,
  currentWeek,
  dateRange,
  project,
  sector,
  currentCompany
) => {
  trakerServiceAppCues('Weekly Commitment');
  const weeklyPlan = tasksWeekly;
  const year = getYearFromThursday(dateRange.start);
  if (weeklyPlan.length) {
    const dataWeeklyPlan = {
      commitment_tasks: weeklyPlan.length,
      week: currentWeek,
      start_date: moment(dateRange.start),
      end_date: moment(dateRange.end),
      realized_tasks: 0,
      sectorId: sector,
      year
    };

    /** bug duplicate */
    let validWeekCommitment = true;
    const weekCommitments = await weekCommitmentService.searchBySector(sector);

    if (weekCommitments.weekcommitments.length) {
      /** Fix for weeks matching for different years */
      const validWeekCommitmentBd = weekCommitments.weekcommitments.filter(
        (el) =>
          el.week === currentWeek && el.year === year && el.sectorId === sector
      );
      if (validWeekCommitmentBd.length) {
        validWeekCommitment = false;
      }
    }
    if (!validWeekCommitment) {
      return false;
    }
    const loggedUser = getSignedUser();
    // const currentProject = project.allProjects.find(
    //   (p) => p.id == project.projectSelected
    // );

    // totangoSetAccountAttributes(
    //   loggedUser,
    //   project.projectSelected,
    //   currentCompany?.name,
    //   currentCompany?.id,
    //   currentProject?.name,
    //   currentProject?.stage,
    //   currentProject?.country
    // );

    totangoEventTracking(
      `p_${project}`,
      loggedUser,
      'Weekly Commitment',
      'Weekly Plan'
    );
    const newWeeklyPlan = await weekCommitmentService.create(dataWeeklyPlan);

    if (newWeeklyPlan.id) {
      /** save tasks commitment */
      const syncMap = weeklyPlan.map(async (task) => {
        const user = getSignedUser();
        const dataTaskCommitment = {
          commitment_percentaje: task.commitment_percentaje || 0,
          realized_percentaje: 0,
          userId: user.id,
          weekcommitmentId: newWeeklyPlan.id,
          current_progress_task: task.progress,
          current_commitment_partial: task.current_commitment_partial || 0,
          taskId: task.id,
          is_last_level:
            !task.children?.length /** last level if task not have childrens */,
          start_date: task.start_date,
          end_date: task.end_date,
          duration: task.duration,
          total_quantity: task.total_quantity,
          plan_endowment: task.plan_endowment,
          subcontractId: task.subcontractId,
          materialId: task.materialId,
          name: task.name
        };
        await taskCommitmentService.create(dataTaskCommitment);
        /** update task */
        task.lean_status = 'Committed';
        await taskService.update(task);
      });
      await Promise.all(syncMap);
    }
    if (getEnvironment() === 'prod') {
      await notifyEmailCommmitment(sector, project, currentWeek, dateRange);
    }
    return newWeeklyPlan;
  }
};

/**
 * This function calculates the commitment percentage for all tree recursively
 * @param {*} task Element which one was modified the commitment percentage
 * @param {*} parent Parent to start calculation of commitment percentage
 * @param {*} haveWeeklyPlanData Item containing weekly plan tasks
 */
export const calculateCommitment = async (
  task,
  parent,
  updateCommitment = () => {},
  haveWeeklyPlan = false,
  activity
) => {
  const childs = parent.tasks || parent.children;
  let percentaje = 0;
  /** Accum commit Parent = (Accum commit * ponderador) + sum hide task (progress * weight) */
  let percentaje_commitment = 0;
  childs &&
    childs.map((t) => {
      const toExtractFrom = t.editValues || t;
      let mult = toExtractFrom.commitment_percentaje;
      if (haveWeeklyPlan && haveWeeklyPlan.taskcommitments) {
        const findWeekly = haveWeeklyPlan.taskcommitments.find(
          (el) => el.taskId === t.id
        );
        mult = findWeekly?.commitment_percentaje;
      }
      if (toExtractFrom.showWeeklyPlan) {
        percentaje_commitment += (toExtractFrom.ponderator / 100) * (mult || 0);
      } else {
        percentaje_commitment +=
          (toExtractFrom.ponderator / 100) * (toExtractFrom.progress || 0);
      }
      percentaje += (toExtractFrom.ponderator / 100) * (mult || 0);
    });

  const toExtractFromParent = parent.editValues || parent;
  if (isNaN(toExtractFromParent)) {
    toExtractFromParent.commitment_percentaje = percentaje_commitment;
  }
  if (parent.tasks) {
    parent.commitmentPercentaje = percentaje_commitment;
    parent.expectedweekAct = percentaje;
  }
  updateCommitment(task, task.commitment_percentaje);

  const newParentId = parent.parent_id || parent.activityId;
  if (newParentId) {
    const newParent = getTask(newParentId, null, activity);
    if (newParent.length) {
      calculateCommitment(
        parent,
        newParent[0],
        updateCommitment,
        haveWeeklyPlan,
        activity
      );
    }
  }
};

export const assignInitialCommitmentPercentaje = (arrayData, dateRange) => {
  const reference = cloneDeep(arrayData);
  const ret = arrayData.activities.map((el) => {
    const tasksRecursive = recursiveTasks(el.tasks, reference, dateRange);
    return {
      ...el,
      tasks: tasksRecursive
    };
  });
  return ret;
};

export const findCurrentWeekCommitment = (
  weekCommitments,
  currentWeek,
  yearState
) =>
  weekCommitments.find(
    (weekCommitment) =>
      weekCommitment.week === currentWeek && weekCommitment.year == yearState
  );

export const filterDeletedCommitmentTasks = (taskCommitments) =>
  taskCommitments.filter((task) => task.taskId === null);

/** get tasks recursively */
const recursiveTasks = (array, reference, dateRange) =>
  /** return mapped array */
  array.map((task) => {
    if (task.children) {
      let findActivity;
      if (task.activityId) {
        findActivity = reference.activities.find(
          (el) => el.id === task.activityId
        );
        if (findActivity) {
          task.expected_cost = calculateExpectedCost(
            task,
            ganttAPI,
            findActivity.calendarId
          );
        }
      }

      const expWeek = calculateExpectedForWeek(
        task,
        ganttAPI,
        findActivity.calendarId,
        null,
        dateRange
      );
      task.commitment_percentaje = parseFloat(expWeek).toFixed(2);
      task.expectedweek = parseFloat(expWeek).toFixed(2);

      if (task.commitment_percentaje < task.progress) {
        let newCompromise = task.progress;
        if (task.total_quantity) {
          newCompromise = task.progress;
        }
        task.commitment_percentaje = newCompromise;
      }
      const quantity_parcial_percentaje = parseFloat(
        parseFloat(task.commitment_percentaje || 0) - parseFloat(task.progress)
      );
      task.quantity_parcial_percentaje = quantity_parcial_percentaje;

      recursiveTasks(task.children, reference, dateRange);
    }
    return task;
  });
