/* eslint-disable no-eval */

/** React components  */
import React, { useState, useEffect, useRef, createRef } from 'react';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/** To animating views easely */
import { Animated } from 'react-animated-css';

import SelectSearch from 'react-select-search';

/** import library for handle dates */
import 'moment/locale/es';
import moment from 'moment';

/** import library for format numbers */
import NumberFormat from 'react-number-format';

/** Import lodash functions */
import { startCase } from 'lodash';
import { base } from '../../../services/base';

/** SVG image */
import IconComponent from '../../../components/Projects/IconSvg';

import {
  calculateExpectedForWeek,
  calculateExpectedCost,
  calculateExpectedForWeekByRangeLast,
  calculateLeanStatus,
  calculatePonderators,
  notifyMessage,
  calculateProgress,
  getEndDateByGantt,
  resizeNameTaskColumn,
  handleLeanStatusColPosition
} from '../../../utils/lookahead-common';

import {
  getEnvironment,
  getFlattenActivities,
  excludeDeep,
  openNotification,
  helmet,
  weeksInYear,
  belongsToRange,
  getTodayWeekWithPday,
  updateResources,
  recursiveTasksFilterWp
} from '../../../utils';
import { materialMassiveSvg } from '../../../utils/svgIcons';
import CustomPaginator from '../../../components/CustomPaginator';
import ModalAddSubContract from '../../../components/Settings/ModalAddSubContract';
import CardTask from '../../../components/CardTask';

import { socket } from '../../../services/socket.service';
import {
  RightOutlined,
  LeftOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';

/** Build components from antd css framework */
import {
  Row,
  Col,
  Popover,
  Avatar,
  Button,
  Spin,
  Icon,
  Empty,
  DatePicker,
  Tooltip,
  Popconfirm,
  Drawer
} from 'antd';

/** PNG to put on users without image */
import fakeAvatar from '../../../assets/img/fake_user.png';
import downloadWeeklyReportBtn from '../../../assets/img/downloadBtnWeeklyReport.png';

import quitDismissIcon from '../../../assets/img/gantt/quit-header-config.png';
/** import common functions from utils */
import { handshake, dynamicSort } from '../../../utils';

import ConstraintForm from '../../../components/LookAhead/Constraints/ConstraintForm';
import differenceBy from 'lodash/differenceBy';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
/** Services */
import {
  activityService,
  taskService,
  userService,
  weekCommitmentService,
  taskCommitmentService,
  subContractService,
  cncService,
  sectorResourcesService,
  notificationService
} from '../../../services';
import {
  sectorBaselineVersionService,
  sectorBaselinePointService
} from '../../../services/index';
// import {sectorBaselineVersionService } from '../../../services/sectorbaselinepoint.service'

import { calendarService } from '../../../services';
import { historicalActivityProgressService } from '../../../services/historicalactivityprogress.service';

/** Function to clone objects on JS */
import cloneDeep from 'lodash/cloneDeep';
import { capitalize } from 'lodash';

/** Plain text CSS file (react pretty features) */
import './index.css';

/** Redux */
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { weeklyplanActions } from '../../../redux/actions/weeklyplanActions';
import { userActions } from '../../../redux/actions/userActions';

/** JSON with table distribution */
import { tableMetadata } from './table.layout';

/** Single table with activity as header and tasks as childs */
import WeeklyPlanActivity from '../../../components/WeeklyPlan/Planning/WeeklyPlanActivity';
import LookaheadOtherGroup from '../../../components/LookaheadOtherGroup';
import ProgressBar from '../../../components/ProgressBarMassive';

/** Massive actions icons */
import constraintMassive from '../../../assets/img/massive/constraint.png';
import datesMassive from '../../../assets/img/massive/dates.png';
import priorityMassive from '../../../assets/img/massive/priority.png';
import progressMassive from '../../../assets/img/massive/progress.png';
import responsableMassive from '../../../assets/img/massive/responsable.png';
import leanMassive from '../../../assets/img/massive/EstadoLean.png';
import { durationMassiveSvg } from '../../../utils/svgIcons';

import AnimatedSortable from '../../../components/AnimatedSortable';
/** Header with filters options component */
import LookaheadFilterHeader from '../../../components/LookaheadFilterHeader';
// import FilterHeader from '../../../components/WeeklyPlan/Planning/FilterHeader'

/** Function to keep states replacing specific elements */
import update from 'immutability-helper';
import { firstBy } from 'thenby';
import { ganttAPI } from '../../../utils/customGanttPlugin';
import { withTranslation } from 'react-i18next';
import { trakerServiceAppCues } from '../../../utils/appcues-util';
import ModalAddResources from '../../../components/ModalAddResources';
import { MassiveTotalMaterial } from '../../../components/MassiveTotalMaterial';
import {
  getActivitiesforWeeklyPlan,
  getActivitiesforWeeklyPlanForWidget
} from '../../../utils';
import { sectorService } from '../../../services/sector.service';
import Widget from '../../../components/WeeklyPlan/Widget';
import { initialState } from '../../../redux/reducers/weeklyPlanWidget';
import CncForm from '../../../components/WeeklyPlan/Cnc/CncForm';
import { getListCncs, getWeekCommitments } from '../weeklyPlan.helper';
import { getCncList, getTasksWithRoute } from '../cncs/cncs.helper';
import { WidgetActions } from '../../../redux/actions/WidgetWeeklyPlanActions';
import useWeeklyplan from '../../../hooks/useWeeklyplan';
import { checkIfExistsWeeklyPlan, saveWeeklyPlan } from '../weeklyPlan.helper';
import useSubtradeUser from '../../../hooks/useSubtradeUser';
import {
  totangoEventTracking,
  totangoSetAccountAttributes
} from '../../../analytics/implements/totango';
import { getCurrentCompany, getSignedUser } from '../../../utils/userUtils';
import { TimerManagerSingleton } from '../../../utils/timerManager';

moment.locale('es');
/** RangePicker builded components to use datepicker from start to end in just one input :) */
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

/**
 * This view shows activities from last level.
 * Then user can through a filter change range date showed.
 * Then, user can add on eagger data model tasks, associating them with the activity ID as a super parent.
 * @param {*} props
 */
function PlanningView(props) {
  const timerManager = TimerManagerSingleton.getInstance();
  const leanStatusColVisibility = useRef(null);
  const subtradeRole = useSubtradeUser();

  let paginatorRef = useRef();
  const { permission, t } = props;
  const refInputMasssiveTotal = createRef();
  /** hooks */
  const [currentPage, setCurrentPage] = useState(0);
  const [yearState, setYearState] = useState(moment().format('YYYY'));
  const [prevYear, setPrevYear] = useState(
    parseInt(moment().format('YYYY')) - 1
  );
  const [currentWeek, setCurrentWeek] = useState();
  const [currentWeekWithPday, setCurrentWeekWithPday] = useState();
  const [haveWeeklyPlan, setHaveWeeklyPlan] = useState(false);
  const [showInitView, setShowInitView] = useState(true);
  const [massiveSelection, setMassiveSelection] = useState([]);
  const [massiveOnProcess, setMassiveOnProcess] = useState(false);
  const [massiveTypePop, setMassiveTypePop] = useState({ visible: false });
  const [massiveProgress, setMassiveProgress] = useState(0);
  const [massiveStatusPop, setMassiveStatusPop] = useState({ visible: false });
  const [massiveCommitmentPop, setMassiveCommitmentPop] = useState({
    visible: false
  });
  const [massiveMaterialPop, setMassiveMAterialPop] = useState({
    visible: false
  });
  const [massivePriorityPop, setMassivePriorityPop] = useState({
    visible: false
  });
  const [massiveLeanPop, setMassiveLeanPop] = useState({ visible: false });
  const [massiveSubcontractPop, setMassiveSubcontractPop] = useState({
    visible: false
  });
  const [commitmentValue, setCommitmentValue] = useState(0);
  const [lastLevelActivities, setLastLevelActivities] = useState({});
  const [popsVisibility, setPopsVisibility] = useState({});
  const [scrollStates, setScrollStates] = useState({});
  const [massiveResponsableState, setMassiveResponsableState] = useState({
    values: []
  });
  const [isTree, setIsTree] = useState({ value: true });
  const [subContracts, setSubcontracts] = useState([]);
  const [planification_day, setPlanification_day] = useState('');
  const [volatileTaskData, setVolatileTaskData] = useState({});
  const [typeResource, setTypeResource] =
    useState('material'); /** material, machinery, rrhh */
  const [resources, setResources] = useState([]);
  const [taskSelected, setTaskSelected] = useState();
  const [sectorWidget, setSectorWidget] = useState(null);
  const [activityWidget, setActivityWidget] = useState([]);
  const [sectorbaselineversion, setSectorbaselineversion] = useState(null);
  const [sectorpoins, setSectorpoins] = useState(null);
  const [sectorpoinsall, setSectorpoinsall] = useState(null);
  const [activitysectorresources, setActivitysectorresources] = useState([]);
  const [widgetreadlweekly, setWidgetreadlweekly] = useState('-');
  const [widgetexpected, setWidgetexpected] = useState('-');
  const [widgetplanned, setWidgetplanned] = useState('-');
  const [widgetcommitment, setWidgetcommitment] = useState('-');
  const [actualPC, setActualPC] = useState('-');
  const [stockloadding, setStockloadding] = useState(true);
  const [selectedResource, setSelectedResource] = useState('');

  let acumaleteTaskResourcePlaned = 0;
  let acumaleteTaskResourceCommitment = 0;
  let total_ppc = 0;
  let total_ppc_realized = 0;
  let acumaletePlanenedValue = 0;
  let acumaleteCommitValue = 0;
  useEffect(() => {
    window.Appcues.page();
  });

  /** redux */
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;
  const stateCompany = useSelector((state) => state.companyState);
  const companyStore = useSelector(
    (state) => state.companyState.currentCompany
  );

  /** handle Form Add Constraint */
  const [visibleForm, setVisibleForm] = useState({ value: false, tasks: [] });
  const [visibleFormSubcontract, setVisibleFormSubcontract] = useState(false);
  const [visibleFormResource, setVisibleFormResource] = useState(false);

  /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);
  const weeklyplanState = useSelector((state) => state.weeklyplanState);
  const dispatch = useDispatch();

  /** Activities to handle table loading */
  const [activities, setActivities] = useState([]);

  /** Calendar to handle table loading */
  const [calendars, setCalendars] = useState([]);

  /** Handle tasks of weekly plan */
  const [tasksWeeklyPlan, setTasksWeeklyPlan] = useState([]);

  /** Array with users that belongs to the same sector that this master plan */
  const [toSelectResponsables, setToSelectResponsables] = useState([]);

  /** Flag to know when component is on loading step */
  const [isLoading, setLoading] = useState(true);

  /** Object to handle start and end dates to show last level activities from master plan  */
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  const [groupBy, setGroupBy] = useState({
    criteria: 'activityId',
    sort: 'asc'
  });
  const [existFilter, setExistFilter] = useState(false);

  /** Variable to view and his components to handle table manipulation by user */
  const [tableConfig, setTableConfig] = useState([]);

  const [logoUser, setLogoUser] = useState(); // image user

  const [resizing, setResizing] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [activityResource, setActivityResource] = useState(null);

  const { height, width } = useWindowDimensions();
  const [cardState, setCardState] = useState({ visible: false });
  // currentTask: null,
  // currentActivity: null

  const [visibleTooltip, setVisibleTooltip] = useState(false);

  /** Full width feature */
  const [totalTablesWidth, setTotalTablesWidth] = useState(0);

  const { widgetData } = useSelector((state) => {
    if (!state.widgetReducer?.widgetData) {
      return initialState;
    }
    return state.widgetReducer;
  });

  const percentage_ops =
    widgetData.byHash[projectState.sectorSelected]?.percentageOption ??
    'percentage';
  const criteria_ops =
    widgetData.byHash[projectState.sectorSelected]?.criteriaOptions ?? 'base';
  const resurce_ops =
    widgetData.byHash[projectState.sectorSelected]?.resourceId;

  useEffect(() => {
    if (resources.length) {
      if (resurce_ops) {
        setSelectedResource(
          ' ' + resources.find((r) => r.id == resurce_ops).material_label
        );
      }
    }
  }, [resources, resurce_ops]);

  useEffect(() => {
    // get user from storage
    const user = getSignedUser();
    setLogoUser(user.image !== undefined ? user.image : null);
  }, []);

  useEffect(() => {
    const loadTableTranslationCallback = () => {
      const copyOfTableData = [...tableMetadata];
      copyOfTableData.map((column) => {
        if (
          column.data_type.includes('/icon') ||
          column.data_type.includes('/string')
        ) {
          column.label = t('tables.weeklyplan.plan.' + column.name + '.label');
          column.from_values.map((option) => {
            option.label = t(
              'tables.weeklyplan.plan.' +
                column.name +
                '.options.' +
                option.value
            );
          });
        } else {
          column.label = t('tables.weeklyplan.plan.' + column.name);
        }
      });
      setTableConfig(copyOfTableData);
      setTableUpdated(true);
    };
    timerManager.registerAutoTimeout(
      loadTableTranslationCallback,
      500,
      'loadTableTranslation3'
    );
  }, []);

  /** handle visible tooltipo of compromise tooltip */
  const handleVisibleChange = (visible) => {
    if (visible) {
      setVisibleTooltip(false);
    }
  };

  /** handle tooltip compromises visibility */
  const handleTooltip = (e) => {
    const type = e.type;
    if (type === 'mouseenter') {
      setVisibleTooltip(true);
    } else if (type === 'mouseleave') {
      setVisibleTooltip(false);
    } else if (type === 'click') {
      setVisibleTooltip(false);
    }
  };

  const getFlattenTasks = (data, activity) =>
    data.reduce((result, next) => {
      result.push(next);
      let childrens = next.tasks || next.children;
      if (childrens) {
        result = [...result, ...getFlattenActivities(childrens)];
        childrens = [];
      }
      return result;
    }, []);

  const checkCompromiseValue = (tasksCheck = tasksWeeklyPlan) => {
    /** only last level tasks */
    const tasks = tasksCheck.filter((el) => !el.children.length);

    const tasksNotGreatherThan0 = [];
    const checkCommitment0 = tasks.every(
      (e) => parseFloat(e.commitment_percentaje) > 0
    );
    if (!checkCommitment0) {
      // look for tasks that do not comply with condition
      tasks.map((el) => {
        if (parseFloat(el.commitment_percentaje) === 0) {
          tasksNotGreatherThan0.push(el.name);
        }
      });
    }

    const checkCommitmentProgress = tasks.some(
      (e) =>
        !e.children.length &&
        parseFloat(e.commitment_percentaje) <= parseFloat(e.progress) &&
        parseFloat(e.progress) !== 100
    );
    const tasksNotGreatherThanProgress = [];
    if (checkCommitmentProgress) {
      tasks.map((el) => {
        if (
          !el.children.length &&
          parseFloat(el.commitment_percentaje) <= parseFloat(el.progress) &&
          parseFloat(el.progress) !== 100
        ) {
          tasksNotGreatherThanProgress.push(el.name);
        }
      });
    }
    const ret = {
      condition: checkCommitment0 && !checkCommitmentProgress,
      tasksNotGreatherThan0,
      tasksNotGreatherThanProgress
    };
    return ret;
  };

  const handleSaveWeeklyPlan = () => {
    const tasksWeeklyPlanRecalc = getFlattenActivities(activities).filter(
      (el) => el.showWeeklyPlan
    );
    const isValidCompromise = checkCompromiseValue(tasksWeeklyPlanRecalc);
    if (!isValidCompromise.condition) {
      const descriptionElement =
        isValidCompromise.tasksNotGreatherThanProgress.join(', ');
      const alertErrorMailExists = {
        title: t('not_valid_committed_title'),
        description:
          t('not_valid_committed_description') +
          '. ' +
          t('tables.weeklyplan.plan.name') +
          ': ' +
          descriptionElement,
        type: 'error'
      };
      openNotification(alertErrorMailExists, 0);
    } else {
      const lastLevelActivitiesFiltered = lastLevelActivities.activities;
      const filterWeek = ['Will', 'Committed'];

      /** sort by default */
      const data = recursiveTasksFilterWp(activities, filterWeek, dateRange);
      const dataFilterActivities = recursiveActivityFilter(data);
      const dataFilterActivitiesShow = dataFilterActivities.filter(
        (el) => el.showWeeklyPlan === true
      );
      const cloneActivities = dataFilterActivitiesShow.filter(
        (el) => el.tasks.length
      );
      // const cloneActivities = cloneDeep(dataFilterActivitiesShow).filter(el => el.tasks.length)
      const flattenActivities = getFlattenActivities(cloneActivities).filter(
        (el) => el.showWeeklyPlan
      );
      setTasksWeeklyPlan(flattenActivities);
      saveWeeklyPlan(flattenActivities);
    }
  };

  useEffect(() => {
    dispatch(weeklyplanActions.notifyLookaheadUpdateGroup());

    const notifyLookaheadUpdateOrderCallback = () => {
      dispatch(weeklyplanActions.notifyLookaheadUpdateOrder());
    };
    timerManager.registerAutoTimeout(
      notifyLookaheadUpdateOrderCallback,
      50,
      'notifyLookaheadUpdateOrder3'
    );
    const notifyLookaheadUpdateFilterCallback = () => {
      dispatch(weeklyplanActions.notifyLookaheadUpdateFilter());
    };
    timerManager.registerAutoTimeout(
      notifyLookaheadUpdateFilterCallback,
      100,
      'notifyLookaheadUpdateFilter3'
    );
  }, [weeklyplanState.notifyChange]);

  /* Real time feature */
  const realTimeTaskAdd = (task) => {
    if (task.lean_status != 'Will') return;
    const lastLvlActivity = getActivity(task.activityId);
    if (lastLvlActivity) {
      const taskToAdd = getTask(task.id, null, lastLvlActivity);
      if (taskToAdd.length) {
      } else {
        if (task.parent_id) {
          const parentTask = getTask(task.parent_id, null, lastLvlActivity);
          if (parentTask.length) {
            parentTask[0].children.push(task);
          }
        } else {
          lastLvlActivity.tasks.push(task);
        }
        handlePopVisibility(false, 'progressMassive');
        // updateState()
      }
    }
  };

  /**
   * This function is the only one that is involve in other groupping
   * @param {*} task Task which was reported to update
   */
  const realTimeTaskUpdate = (task) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      const lastLvlActivity = getActivity(task.activityId);
      if (lastLvlActivity) {
        const taskToUpdate = getTask(task.id, null, lastLvlActivity);
        if (taskToUpdate.length) {
          Object.keys(task).map((attr) => {
            if (
              attr != 'children' &&
              attr != 'createdAt' &&
              attr != 'activityId' &&
              attr != 'id' &&
              attr != 'parent_id' &&
              attr != 'updatedAt' &&
              attr != 'active'
            ) {
              taskToUpdate[0][attr] = task[attr];
            }
          });
        }
      }
    } else {
      activities.map((ac) => {
        /** Reference from state */
        const doesExistAtReference = ac.tasks.find((t) => t.id == task.id);
        if (doesExistAtReference) {
          Object.keys(task).map((attr) => {
            if (
              attr != 'children' &&
              attr != 'createdAt' &&
              attr != 'activityId' &&
              attr != 'id' &&
              attr != 'parent_id' &&
              attr != 'updatedAt' &&
              attr != 'active'
            ) {
              doesExistAtReference[attr] = task[attr];
            }
          });
        }
      });
    }
    handlePopVisibility(false, 'progressMassive');
  };

  const realTimeTaskDelete = (task) => {
    const lastLvlActivity = getActivity(task.activityId);
    if (lastLvlActivity) {
      const taskToDelete = getTask(task.id, null, lastLvlActivity);
      if (taskToDelete.length) {
        if (task.parent_id) {
          /** remove from parent task */
          const parentTask = getTask(task.parent_id, null, lastLvlActivity);
          if (parentTask.length) {
            parentTask[0].children = parentTask[0].children.filter((t) => {
              if (t.id != task.id) return true;
              return false;
            });
          }
        } else {
          /** remove from activity */
          lastLvlActivity.tasks = lastLvlActivity.tasks.filter((t) => {
            if (t.id != task.id) return true;
            return false;
          });
        }
        handlePopVisibility(false, 'progressMassive');
        // updateState()
      }
    }
  };

  const realTimeActivityUpdate = (activity) => {
    const lastLvlActivity = getActivity(activity.id);
    if (lastLvlActivity) {
      lastLvlActivity.progress = activity.progress;
      lastLvlActivity.hasCustomPonderator = activity.hasCustomPonderator;
      handlePopVisibility(false, 'progressMassive');
      // updateState()
    }
  };

  useEffect(
    () =>
      // socket.on('task_update_' + projectState.sectorSelected, realTimeTaskUpdate)

      // socket.on('task_delete_' + projectState.sectorSelected, realTimeTaskDelete)

      // socket.on('task_add_' + projectState.sectorSelected, realTimeTaskAdd)

      // socket.on('activity_update_' + projectState.sectorSelected, realTimeActivityUpdate)

      () => {
        socket.off('task_add_' + projectState.sectorSelected, realTimeTaskAdd);
        socket.off(
          'task_delete_' + projectState.sectorSelected,
          realTimeTaskDelete
        );
        socket.off(
          'task_update_' + projectState.sectorSelected,
          realTimeTaskUpdate
        );
        socket.off(
          'activity_update_' + projectState.sectorSelected,
          realTimeActivityUpdate
        );
      },
    [projectState.sectorSelected, activities, groupBy]
  );

  useEffect(() => {
    if (!resizing) {
      let tablesWidth = 0;
      tableConfig.map((el) => {
        if (el.visible) {
          tablesWidth += el.width;
        }
      });
      setTotalTablesWidth(tablesWidth);
    }
  }, [tableConfig]);

  useEffect(() => {
    if (paginatorRef.current) {
      paginatorRef.current.resetAfterColumnIndex(0);
    }
  }, [totalTablesWidth]);

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  const [tableUpdated, setTableUpdated] = useState(false);
  /** This effect allows to load initial lookahead range, and reacts to change on sectorSelected from redux */
  useEffect(() => {
    if (tableConfig.length) {
      /** get week number */
      const todayWeek = getTodayWeekWithPday(projectState);
      setCurrentWeekWithPday(todayWeek);

      /** set range according current week */
      getRangeDateOfWeek(todayWeek);
      setCurrentWeek(todayWeek);
      /** Loads activities with their tasks */
      getLookahead(todayWeek);
    }
  }, [projectState.sectorSelected, tableUpdated]);

  /** side effect for currentWeek */
  useEffect(() => {
    /** get week number */
    if (currentWeek) {
      getRangeDateOfWeek(currentWeek);
      getLookahead(currentWeek);
    }
  }, [currentWeek, showInitView]);

  const [avoidLoading, setAvoidLoading] = useState({ value: false });

  useEffect(() => {
    // get user from storage
    if (activities.length > 0) {
      if (sectorWidget) {
        if (sectorbaselineversion) {
          if (sectorpoins) {
            if (percentage_ops) {
              if (stockloadding) {
                getWidgetData(
                  activityWidget,
                  percentage_ops != 'percentage',
                  percentage_ops == 'percentage' ? 0 : resurce_ops
                );
                if (activityWidget.length) {
                }
              }
            }
          }
        }
      }
    }
  }, [
    sectorWidget,
    sectorbaselineversion,
    sectorpoins,
    activities,
    sectorpoinsall,
    activitysectorresources,
    percentage_ops,
    criteria_ops
  ]);

  const getWidgetData = async (activitys, type, resource_id) => {
    // sectorbaselineversion.accumulatedDuration
    // eslint-disable-next-line no-constant-condition
    if (true) {
      setStockloadding(false);
      let activityreorces = [];

      const data_ = {
        sectorId: projectState.sectorSelected,
        week: currentWeek
      };
      // const objCommitment = await weekCommitmentService.getByWeeklyWidget(data_)
      const projects = projectState.allProjects.find(
        (p) => p.id == projectState.projectSelected
      );
      const total_activity =
        await sectorBaselineVersionService.showProgressReal(
          projectState.sectorSelected
        );
      const real_activitys =
        await historicalActivityProgressService.showProgressReal(
          projectState.sectorSelected,
          dateRange.start.replaceAll('/', '-'),
          dateRange.end.replaceAll('/', '-')
        );
      const activityreorce_temp = await sectorResourcesService.activityresource(
        resource_id,
        projectState.sectorSelected
      );
      activityreorces = activityreorce_temp.sectorresource;
      const accumulatedDuration =
        projects.activity_creter == 'cost'
          ? total_activity.base_cost
          : total_activity.base_duration;

      // total
      let total_follower = 0;
      let total_base = 0;
      let total_real = 0;
      const total_planficate = 0;
      const total_commitment = 0;
      // let total_ppc = 0;
      // let total_ppc_realized = 0;
      if (criteria_ops == 'following') {
        const objGetLookAhead = {
          sector_id: projectState.sectorSelected,
          start: dateRange.start,
          end: dateRange.end,
          ignore_dates: true
        };
        // getLookaheadWeeklyPlan
        const lastLevelActivitiesGet =
          await activityService.getLookaheadFolower(objGetLookAhead);
        if (lastLevelActivitiesGet) {
          lastLevelActivitiesGet.activities.map((activity) => {
            const point = sectorpoinsall.find(
              (p) => p.activityId == activity.id
            );
            if (point) {
              const week_percentaje = getweekPercentaje(activity);
              let total_percentaje = 0;
              if (projects.activity_creter.toLowerCase() == 'cost') {
                total_percentaje =
                  (point.cost * 100) / total_activity.base_cost;
              } else if (projects.activity_creter.toLowerCase() == 'hh') {
                total_percentaje =
                  (point.hh_work * 100) / total_activity.base_hh;
              } else {
                total_percentaje =
                  (point.duration * 100) /
                  (sectorbaselineversion.accumulatedDuration
                    ? sectorbaselineversion.accumulatedDuration
                    : total_activity.base_duration / point.hoursPerDay);
              }

              if (type) {
                const point_resuorce = activityreorces.find(
                  (p) => p.activityId == activity.id
                );
                if (point_resuorce) {
                  total_follower =
                    total_follower +
                    point_resuorce.quantity * (week_percentaje / 100);
                }
              } else {
                total_follower =
                  total_follower + total_percentaje * (week_percentaje / 100);
              }
            }
          });
        }

        setWidgetexpected(total_follower.toFixed(2));
      } else {
        sectorpoins.map((point) => {
          const week_percentaje_base = getweekPercentaje(point);

          let total_percentaje = 0;
          if (projects.activity_creter.toLowerCase() == 'cost') {
            total_percentaje = (point.cost * 100) / total_activity.base_cost;
          } else if (projects.activity_creter.toLowerCase() == 'hh') {
            total_percentaje = (point.hh_work * 100) / total_activity.base_hh;
          } else {
            total_percentaje =
              (point.duration * 100) /
              (sectorbaselineversion.accumulatedDuration
                ? sectorbaselineversion.accumulatedDuration
                : total_activity.base_duration / point.hoursPerDay);
          }
          if (type) {
            const point_resuorce = activityreorces.find(
              (p) => p.activityId == point.activity.id
            );
            if (point_resuorce) {
              total_base =
                total_base +
                point_resuorce.quantity * (week_percentaje_base / 100);
            }
          } else {
            total_base =
              total_base + total_percentaje * (week_percentaje_base / 100);
          }
        });

        setWidgetexpected(total_base.toFixed(2));
      }

      for (const activity of activities) {
        let total_percentaje = 0;
        const point = sectorpoinsall.find((p) => p.activityId == activity.id);

        if (point) {
          if (projects.activity_creter.toLowerCase() == 'cost') {
            total_percentaje = (point.cost * 100) / total_activity.base_cost;
          } else if (projects.activity_creter.toLowerCase() == 'hh') {
            total_percentaje = (point.hh_work * 100) / total_activity.base_hh;
          } else {
            total_percentaje =
              (point.duration * 100) /
              (sectorbaselineversion.accumulatedDuration
                ? sectorbaselineversion.accumulatedDuration
                : total_activity.base_duration / point.hoursPerDay);
          }
          activity.tasks.map((task) => {
            getweekPercentajeRecursive(task, activity, total_percentaje / 100);
          });
        } else {
          activity.tasks.map((task) => {
            getweekPercentajeRecursive(task, activity, 0);
          });
        }
      }

      window.activities = activities;
      // acumaleteCommitValue
      /* activities.map((activitie) => {
                let total_activity_planification = 0;
                let total_activity_commitment = 0;
                const point = sectorpoinsall.find((p) => p.activityId == activitie.id)
                let total_percentaje = 0;
                if (projects.activity_creter.toLowerCase() == 'cost') {
                    total_percentaje = point.cost * 100 / (total_activity.base_cost)

                } else {
                    total_percentaje = point.duration * 100 / (sectorbaselineversion.accumulatedDuration / point.hoursPerDay)
                }
                if (point) {
                    const task_material_aculated = 0;
                    activitie.tasks.map((task) => {

                        if (task.showWeeklyPlan || task.showWeeklyPlanPonderable) {
                            if (task.showWeeklyPlan || task.showWeeklyPlanPonderable) {
                                total_activity_planification = total_activity_planification + (task.ponderator * (task.week_expected / 100))

                            }

                            if (task.lean_status == 'Committed' || task.showWeeklyPlanPonderableCommitment) {
                                total_activity_commitment = total_activity_commitment + (task.ponderator * (task.week_expected / 100))
                            }
                        }
                    })

                    // const total_percentaje = point.duration * 100 / (sectorbaselineversion.accumulatedDuration / point.hoursPerDay)
                    if (type) {

                    } else {
                        //
                        // const total_percentaje = point.duration * 100 / (sectorbaselineversion.accumulatedDuration / point.hoursPerDay)
                        total_planficate = total_planficate + (total_percentaje * (total_activity_planification / 100))
                        total_commitment = total_commitment + (total_percentaje * (total_activity_commitment / 100))
                    }
                }
            })
            */
      if (type) {
        setWidgetplanned(acumaleteTaskResourcePlaned.toFixed(2));
        setWidgetplanned(acumaleteTaskResourcePlaned.toFixed(2));
      } else {
        setWidgetplanned(acumaletePlanenedValue.toFixed(2));
        setWidgetcommitment(acumaleteCommitValue.toFixed(2));
      }

      total_ppc > 0
        ? setActualPC(total_ppc_realized + '/' + total_ppc)
        : setActualPC('-');

      real_activitys.historicalactivityprogress.map((activity) => {
        const act = real_activitys.historicalactivityprogresslast.find(
          (p) => p.id == activity.id
        );
        let real_progress = 0;
        // activity
        if (act) {
          real_progress =
            activity.historicalactivityprogresses[0].progress -
            act.historicalactivityprogresses[0].progress;
        } else {
          real_progress = activity.historicalactivityprogresses[0].progress;
        }
        if (activity.baseline_points.length > 0) {
          const point = activity.baseline_points[0];

          if (type) {
            const point_resuorce = activityreorces.find(
              (p) => p.activityId == activity.id
            );
            if (point_resuorce) {
              total_real =
                total_real + point_resuorce.quantity * (real_progress / 100);
            }
          } else {
            let total_percentaje = 0;
            if (projects.activity_creter.toLowerCase() == 'cost') {
              total_percentaje = (point.cost * 100) / total_activity.base_cost;
            } else if (projects.activity_creter.toLowerCase() == 'hh') {
              total_percentaje = (point.hh_work * 100) / total_activity.base_hh;
            } else {
              total_percentaje =
                (point.duration * 100) /
                (sectorbaselineversion.accumulatedDuration
                  ? sectorbaselineversion.accumulatedDuration
                  : total_activity.base_duration / point.hoursPerDay);
            }

            /* const real = {
                            name : activity.name
                        } */
            total_real = total_real + total_percentaje * (real_progress / 100);
          }
        }
      });
      setWidgetreadlweekly(total_real.toFixed(2));
      setStockloadding(true);
    }
  };

  const getweekPercentaje = (activity) => {
    const expo_week_last = calculateExpectedForWeekByRangeLast(
      activity,
      ganttAPI,
      activity.calendarId,
      null,
      dateRange
    );
    const expo_week = calculateExpectedForWeek(
      activity,
      ganttAPI,
      activity.calendarId,
      null,
      dateRange
    );

    return expo_week - expo_week_last;
  };

  const getweekPercentajeRecursive = (task, activity, ponderator) => {
    const childs = task.children;

    if (childs.length) {
      const sume_poderator = (task.ponderator / 100) * ponderator;
      childs &&
        childs.map((t) => {
          getweekPercentajeRecursive(t, activity, sume_poderator);
        });

      let week_expected = 0;
      // let total_ppc_realized = 0
      let total_ppc = 0;
      let showWeeklyPlanPonderable = false;
      let showWeeklyPlanPonderableCommitment = false;
      childs &&
        childs.map((t) => {
          week_expected =
            week_expected + t.ponderator * (t.week_expected / 100);
          // total_ppc_realized = total_ppc_realized + t.total_ppc_realized
          total_ppc = total_ppc + t.total_ppc;
          if (!showWeeklyPlanPonderable) {
            if (t.showWeeklyPlan) {
              showWeeklyPlanPonderable = true;
            }
          }
          if (t.lean_status == 'Committed') {
            showWeeklyPlanPonderableCommitment = true;
          }
        });
      task.showWeeklyPlanPonderable = showWeeklyPlanPonderable;
      task.showWeeklyPlanPonderableCommitment =
        showWeeklyPlanPonderableCommitment;

      // task.showWeeklyPlan = showWeeklyPlanPonderable
      task.week_expected = week_expected;
      // task.total_ppc_realized = total_ppc_realized
      task.total_ppc = total_ppc;
    } else {
      if (task.showWeeklyPlan) {
        const expo_week_last = calculateExpectedForWeekByRangeLast(
          task,
          ganttAPI,
          activity.calendarId,
          null,
          dateRange
        );
        const expo_week = calculateExpectedForWeek(
          task,
          ganttAPI,
          activity.calendarId,
          null,
          dateRange
        );
        task.week_expected =
          task.ponderator * ((expo_week - expo_week_last) / 100);

        if (task.materialId) {
          if (task.materialId == resurce_ops) {
            if (task.total_quantity) {
              acumaleteTaskResourcePlaned =
                acumaleteTaskResourcePlaned +
                ((expo_week - expo_week_last) / 100) * task.total_quantity;
              if (task.lean_status == 'Committed') {
                acumaleteTaskResourceCommitment =
                  acumaleteTaskResourceCommitment +
                  ((expo_week - expo_week_last) / 100) * task.total_quantity;
              }
            }
          }
        }

        if (task.lean_status == 'Committed') {
          task.total_ppc = 1;
          // task.total_ppc_realized = 1;
          total_ppc = total_ppc + 1;
          task.showWeeklyPlanPonderable = true;

          // haveWeeklyPlan
          if (haveWeeklyPlan) {
            const find = haveWeeklyPlan.taskcommitments.find(
              (e) => e.taskId === task.id
            );

            let partial_commitment = 0;
            let valueColumn = 0;
            if (find && !isNaN(find.commitment_percentaje)) {
              valueColumn = find.commitment_percentaje || 0;
              // valueColumn -= find.current_progress_task
              partial_commitment =
                find.commitment_percentaje - find.current_progress_task;
            } else {
              partial_commitment = task.commitment_percentaje - task.progress;
            }
            acumaleteCommitValue =
              acumaleteCommitValue +
              ponderator * (task.ponderator * (partial_commitment / 100));
            if (task.progress >= valueColumn) {
              total_ppc_realized = total_ppc_realized + 1;
            }
          }
        }
        acumaletePlanenedValue =
          acumaletePlanenedValue +
          ponderator * (task.ponderator * ((expo_week - expo_week_last) / 100));
      } else {
        task.week_expected = 0;
      }
    }
  };

  /**
   * This function get activities and responsables to use them at DOM
   */
  async function getLookahead(
    week = currentWeek,
    avoidSetState = false,
    reload = true
  ) {
    if (reload) setLoading(true);
    const calendars = await calendarService.showBySector(
      projectState.sectorSelected
    );
    setCalendars(calendars.calendar);

    const haveWeekly = await checkIfExistsWeeklyPlan(
      week,
      moment().format('YYYY'),
      projectState.sectorSelected
    );
    setHaveWeeklyPlan(haveWeekly);

    const sectorResp = await sectorService.showByLooaheadWeekly(
      projectState.sectorSelected
    ); // getSectorsByProject(idProject)
    setSectorWidget(sectorResp.sector);
    const sectorBeslinePoint =
      await sectorBaselineVersionService.showBySectorActive(
        projectState.sectorSelected
      );
    if (sectorBeslinePoint) {
      const points = getActivitiesforWeeklyPlanForWidget(
        sectorBeslinePoint.sectorbaselineversion.points,
        { start: dateRange.start, end: dateRange.end }
      );
      setSectorbaselineversion(sectorBeslinePoint.sectorbaselineversion);
      setSectorpoins(points);
      setSectorpoinsall(sectorBeslinePoint.sectorbaselineversion.points);
      /** set load to true */
    }

    // if (!avoidLoading.value) {
    //    setLoading(true)
    // }

    setCurrentPage(0);
    const objGetLookAhead = {
      sector_id: projectState.sectorSelected,
      start: dateRange.start,
      end: dateRange.end,
      ignore_dates: true
    };
    // getLookaheadWeeklyPlan
    const lastLevelActivitiesGet =
      await activityService.getLookaheadWeeklyPlan(objGetLookAhead);

    assignInitialCommitmentPercentaje(lastLevelActivitiesGet);
    const resources = lastLevelActivitiesGet.resource || [];
    setLastLevelActivities(lastLevelActivitiesGet);

    /** mark showWeeklyPlan recursively */

    /** get Subcontracts */
    const subcontractsGet = await subContractService.getByProject(
      projectState.projectSelected
    );
    if (subcontractsGet) {
      const typeFrom = tableConfig.find((col) => col.name == 'subcontractId');
      typeFrom.from_values.splice(0, typeFrom.from_values.length);
      const subContractsOrdered = subcontractsGet.subcontracts.sort(
        dynamicSort('name')
      );
      /** sort by value (order : 1, asc, -1 desc ) */
      subContractsOrdered.map((type, index) => {
        typeFrom.from_values.push({
          value: type.id,
          label: type.name,
          weigth: index + 1
        });
      });
      setTableConfig([...tableConfig]);
      setSubcontracts(subContractsOrdered);
    }

    const materialFrom = tableConfig.find(
      (col) => col.identifier == 'material'
    );
    materialFrom.from_values.splice(0, materialFrom.from_values.length);

    resources.map((resource, index) => {
      const toPush = {
        value: resource.id,
        label: resource.name,
        weigth: index + 1
      };

      switch (resource.type) {
        case 'material':
          materialFrom.from_values.push(toPush);
          break;
      }
    });

    setResources(resources || []);
    setTableConfig([...tableConfig]);

    const responsables = await userService.getBySector(
      projectState.sectorSelected
    );
    setToSelectResponsables(responsables.users);

    setLastLevelActivities(lastLevelActivitiesGet);
    if (avoidSetState) {
      return lastLevelActivitiesGet;
    }
    return refreshView(lastLevelActivitiesGet, false, haveWeekly);
  }

  const refreshView = (
    lastLevelActivitiesRefresh = lastLevelActivities,
    updateview = false,
    haveWeeklyData = null,
    render = true
  ) => {
    /** map activities and set hidden, according to filters */
    const lastLevelActivitiesFiltered = lastLevelActivitiesRefresh.activities;
    const filterWeek = ['Will', 'Committed'];

    /** sort by default */
    const data = recursiveTasksFilterWp(
      lastLevelActivitiesFiltered,
      filterWeek,
      dateRange
    );
    const dataFilterActivities = recursiveActivityFilter(data);
    const dataFilterActivitiesShow = dataFilterActivities.filter(
      (el) => el.showWeeklyPlan === true
    );
    const cloneActivities = dataFilterActivitiesShow.filter(
      (el) => el.tasks.length
    );
    // const cloneActivities = cloneDeep(dataFilterActivitiesShow).filter(el => el.tasks.length)
    const flattenActivities = getFlattenActivities(cloneActivities).filter(
      (el) => el.showWeeklyPlan
    );
    setTasksWeeklyPlan(flattenActivities);
    if (haveWeeklyData) {
      /** recalc activity on component activiy */
      cloneActivities.map((act) => {
        act.recalcCommitment = true;
        return act;
      });
    }

    /** If data were fetch succesfully, we pass this data to component states */
    if (lastLevelActivities || updateview) {
      if (render) setLoading(false);
      setActivities(
        cloneActivities.sort(
          (a, b) => parseInt(a.correlative_id) - parseInt(b.correlative_id)
        )
      );
    }
    return cloneActivities;
  };

  const assignInitialCommitmentPercentaje = (arrayData) => {
    const reference = cloneDeep(arrayData);
    const ret = arrayData.activities.map((el) => {
      const tasksRecursive = recursiveTasks1(el.tasks, reference);
      return {
        ...el,
        tasks: tasksRecursive
      };
    });
    // .filter(el => el.tasks.length)
    return ret;
  };

  /** get tasks recursively */
  const recursiveTasks1 = (array, reference) =>
    /** return mapped array */
    array.map((task) => {
      if (task.children) {
        let findActivity;
        if (task.activityId) {
          findActivity = reference.activities.find(
            (el) => el.id === task.activityId
          );
          if (findActivity) {
            task.expected_cost = calculateExpectedCost(
              task,
              ganttAPI,
              findActivity.calendarId
            );
          }
        }

        const expWeek = calculateExpectedForWeek(
          task,
          ganttAPI,
          findActivity.calendarId,
          null,
          dateRange
        );
        task.commitment_percentaje = parseFloat(expWeek).toFixed(2);
        task.expectedweek = parseFloat(expWeek).toFixed(2);

        if (task.commitment_percentaje < task.progress) {
          let quantity_parcial = 0;
          let newCompromise = task.progress;
          if (task.total_quantity) {
            newCompromise =
              task.progress + (quantity_parcial * 100) / task.total_quantity;
          }
          task.commitment_percentaje = newCompromise;
          quantity_parcial = 0;
        }
        const quantity_parcial_percentaje = parseFloat(
          parseFloat(task.commitment_percentaje || 0) -
            parseFloat(task.progress)
        );
        task.quantity_parcial_percentaje = quantity_parcial_percentaje;

        recursiveTasks1(task.children, reference);
      }
      return task;
    });
  const getRangeDateOfWeek = async (week) => {
    // fix week 2021

    // setCurrentWeek(week)
    const year = moment(new Date()).format('YYYY');
    /** get day 3 of week */
    const project = projectState.allProjects.find(
      (pr) => pr.id === projectState.projectSelected
    );
    let planificationDay;

    if (year === '2022') {
      week -= 1;
    }
    const temp_yearState = yearState;
    /* if (week === 1) {
            week = 53
            temp_yearState = yearState-1
           setYearState
        } */

    let day3ofWeek = moment(temp_yearState + '/01/01', 'YYYY/MM/DD')
      .isoWeek(week)
      .day(3);

    if (parseInt(year) !== parseInt(temp_yearState)) {
      day3ofWeek = moment(`${temp_yearState}/01/07`, 'YYYY/MM/DD')
        .isoWeek(week)
        .day(3);
    }
    const mm = day3ofWeek.format('MM');
    const dd = day3ofWeek.format('DD');
    let fixDay3ofWeek;
    if (week === 0 && mm === '12') {
      fixDay3ofWeek = moment(
        `${parseInt(temp_yearState) - 1}/${mm}/${dd}`,
        'YYYY/MM/DD'
      );
    } else {
      fixDay3ofWeek = moment(
        `${parseInt(temp_yearState)}/${mm}/${dd}`,
        'YYYY/MM/DD'
      );
    }

    if (project.planification_day == 0) {
      planificationDay = -1;
    } else {
      planificationDay = project.planification_day - 1;
    }
    setPlanification_day(project.planification_day);

    /** get first day of week */

    const from_date = fixDay3ofWeek
      .clone()
      .startOf('isoWeek')
      .add(planificationDay, 'day');
    const to_date = from_date.clone().add(6, 'day');

    const start = from_date.toISOString().split('T')[0].split('-').join('/');
    const end = to_date.toISOString().split('T')[0].split('-').join('/');

    /** Then using set state hook load this vars, to let virtual dom load datepickers with correct dates */
    setDateRange((prev) => {
      prev.start = start;
      prev.end = end;
      return prev;
    });
  };

  const checkIfExistsWeeklyPlan = async (week) => {
    const data = {
      week: parseInt(week),
      sectorId: parseInt(projectState.sectorSelected),
      year: yearState
    };
    const have = await weekCommitmentService.searchbyweek(data);

    let ret = false;
    if (have.commitment && have.commitment.id) {
      ret = have.commitment;
    }
    return ret;
  };

  const getFlat = (data) =>
    data.reduce((result, next) => {
      result.push(next);
      let name = next.tasks || next.children;
      if (name) {
        result = [...result, ...getFlat(name)];
        name = [];
      }
      return result;
    }, []);
  // .filter((e) => e.hide !== undefined && e.hide === false);
  const recursiveActivityFilter = (array) =>
    /** return mapped array */
    array.map((el) => {
      const tmpArr = [];
      tmpArr.push(el);
      const getFlatten = getFlattenActivities(tmpArr);
      const haveWp = getFlatten.some((el) => el.showWeeklyPlan);
      el.showWeeklyPlan = haveWp;
      return el;
    });
  /**
   * This function handles change massive responsable change
   */
  const massiveResponsableHandler = async () => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      const asyncMap = massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.responsables = [];
          massiveResponsableState.values.map((res) => {
            reference.responsables.push(res);
          });
          updateAsyncTask(reference);
          const user = getSignedUser();
          reference.sectorId = projectState.sectorSelected;
          for (let i = 0; i < reference.responsables.length; i++) {
            await notificationService.createConstraint({
              user,
              constraint: reference,
              module: 'tasks',
              type_notification: 'assign_responsible',
              userId: reference.responsables[i].id
            });
          }
        }
      });
      await Promise.all(asyncMap);
      dispatch(weeklyplanActions.notifyLookaheadUpdateGroup());
      /** Other group by logic massive update */
    } else {
      const asyncMap = massiveSelection.map(async (selection) => {
        const referenceActivity = activities.find((el) =>
          el.tasks.find((elem) => elem.id === selection.id)
        );
        if (referenceActivity) {
          const reference = referenceActivity.tasks.find(
            (task) => task.id === selection.id
          );
          if (reference) {
            reference.responsables = [];
            massiveResponsableState.values.map((res) => {
              reference.responsables.push(res);
            });
            updateAsyncTask(reference);
            const user = getSignedUser();
            reference.sectorId = projectState.sectorSelected;
            for (let i = 0; i < reference.responsables.length; i++) {
              await notificationService.createConstraint({
                user,
                constraint: reference,
                module: 'tasks',
                type_notification: 'assign_responsible',
                userId: reference.responsables[i].id
              });
            }
          }
        }
      });
      await Promise.all(asyncMap);
      if (groupBy.criteria === 'responsables') {
        const notifyLookaheadUpdateGroupCallback = () => {
          dispatch(weeklyplanActions.notifyLookaheadUpdateGroup());
        };
        timerManager.registerAutoTimeout(
          notifyLookaheadUpdateGroupCallback,
          300,
          'notifyLookaheadUpdateGroup4'
        );
      }
    }
    handlePopVisibility(false, 'responsableMassive');
    dispatch(userActions.setUserUpdate());
  };

  /**
   * This function handles the massive start date change
   * @param {*} date date f rom datepicker at dom
   */
  const massiveStartHandle = (date) => {
    const arrIdTask = [];
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.start_date = date;
          getEndDateByGantt(reference, activity);
          updateAsyncTask(reference);
          const belongs = belongsToRange(moment(date, 'YYYY/MM/DD'), [
            { ...dateRange }
          ]);
          if (!belongs) {
            arrIdTask.push(reference.id);
          }
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            let reference = doesExistAtReference;
            reference.start_date = date;
            getEndDateByGantt(reference, reference.activityObject);
            reference = cloneDeep(reference);
            delete reference.activityObject;
            updateAsyncTask(reference);
          }
        });
      });
    }
    const newData = getActivitiesforWeeklyPlan(activities, dateRange);
    const newArray = lastLevelActivities;
    newArray.activities = newData;
    updateRender(newData);
    handleVisibleMassivePop(false, 'datesMassive');
  };

  const massiveDurationHandle = (date) => {
    const arrIdTask = [];
    const value = refInputMasssiveTotal.current.state.value;
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];

          reference.duration = Math.abs(Number(value));
          /** We give the lookahead task to gantt API to get duration and end_date */
          getEndDateByGantt(reference, activity);
          /** Calculates ponderators feature */
          calculatePonderators(
            parentTask || activity,
            activity,
            updateAsyncTask,
            projectState
          );
          /** Apply changes */
          updateAsyncTask(reference);
        }
      });
    }
    const newData = getActivitiesforWeeklyPlan(activities, dateRange);
    const newArray = lastLevelActivities;
    newArray.activities = newData;
    updateRender(newData);
    handlePopVisibility(false, 'durationMassive');
  };

  const modifyGroupBy = async (newGroupBy, data = activities) => {
    avoidLoading.value = true;
    const todayWeek = getTodayWeekWithPday(projectState);
    if (newGroupBy.criteria == 'activityId') {
      if (!isTree.value) {
        // const dataTmp = await getLookahead(todayWeek, true, false)
        const newData = lastLevelActivities;
        const dataToUpdate = refreshView(newData);
        isTree.value = true;
        dataToUpdate.sort(
          firstBy('correlative_id', {
            direction: newGroupBy.sort,
            ignoreCase: true
          })
        );
        if (newGroupBy.sort.toLowerCase() == 'asc') {
          dataToUpdate.sort(
            (a, b) => parseInt(a.correlative_id) - parseInt(b.correlative_id)
          );
        } else {
          dataToUpdate.sort(
            (a, b) => parseInt(b.correlative_id) - parseInt(a.correlative_id)
          );
        }
        isTree.value = true;

        refreshView({ activities: dataToUpdate }, true);
        setGroupBy(newGroupBy);
        setIsTree({ ...isTree });
        avoidLoading.value = false;
        return false;
      }
    } else {
      let col = tableConfig.filter((el) => el.name == newGroupBy.criteria);
      if (col.length != 0) {
        col = col[0];
        if (
          newGroupBy.criteria == groupBy.criteria &&
          newGroupBy.sort != groupBy.sort
        ) {
          data.sort(
            firstBy('value', { direction: newGroupBy.sort, ignoreCase: true })
          );
        } else {
          isTree.value = true;
          if (isTree.value || newGroupBy.criteria != groupBy.criteria) {
            const objGetLookAhead = {
              sector_id: projectState.sectorSelected,
              start: dateRange.start,
              end: dateRange.end,
              ignore_dates: true
            };
            data = lastLevelActivities.activities;
            isTree.value = false;
          }
          if (groupBy.criteria !== newGroupBy.criteria) {
            quitDismissHandler();
          }

          let newData = [];
          if (col.from_values == 'toSelectResponsables') {
            newData = toSelectResponsables.map((el) => ({
              value: el[col.el_to_extract_from],
              name: el.name,
              img: el.image,
              tasks: []
            }));
            /** Bug fix: 278 airtable ID */
            newData.push({
              value: null,
              name: t('filters_label.no_responsible'),
              img: null,
              tasks: []
            });
          } else if (col.from_values.length) {
            let newSpace = col.from_values;
            if (col.mixed) {
              newSpace = col.from_values.filter((from) => {
                if (col.mixed_from.includes(from.value)) {
                  return false;
                }
                return true;
              });
            }

            newData = newSpace.map((el) => ({
              value: el.value,
              tasks: [],
              label: el.label
            }));
          }

          data.map((activity) => {
            activity.tasks.map((task) => {
              newData.map((singleParent) => {
                singleParent.activities = activity.activities;
                /** Save object reference */
                task.activityObject = activity;
                if (col.name == 'responsables') {
                  if (task[col.name].length) {
                    task[col.name].map((responsable) => {
                      if (
                        responsable[col.el_to_extract_from] ==
                        singleParent.value
                      ) {
                        task.parentIdForGantt = singleParent.id;
                        singleParent.tasks.push(task);
                      }
                    });
                  } else if (!singleParent.value) {
                    task.parentIdForGantt = singleParent.id;
                    singleParent.tasks.push(task);
                  }
                } else {
                  if (task[col.name] == singleParent.value) {
                    task.parentIdForGantt = singleParent.id;
                    singleParent.tasks.push(task);
                  }

                  if (task.children.length && activity.unique_id) {
                    const allDeepTasks = getFlattenTasks(
                      task.children,
                      activity
                    );
                    if (allDeepTasks.length) {
                      allDeepTasks.map((taskDeep) => {
                        const findXx = singleParent.tasks.find(
                          (el1) => el1.id === taskDeep.id
                        );
                        if (!findXx) {
                          if (taskDeep[col.name] == singleParent.value) {
                            if (
                              taskDeep.activityObject === null ||
                              taskDeep.activityObject === undefined
                            ) {
                              taskDeep.activityObject = activity;
                            }
                            taskDeep.parentIdForGantt = singleParent.id;
                            singleParent.tasks.push(taskDeep);
                          }
                        }
                      });
                    }
                  }
                }
              });
            });
          });
          data = newData;
          data.sort(
            firstBy('value', { direction: newGroupBy.sort, ignoreCase: true })
          );
        }
      }
    }
    refreshView({ activities: data }, true);
    setGroupBy(newGroupBy);
    setIsTree({ ...isTree });
    avoidLoading.value = false;
  };

  /**
   * This function updates the state of table metadata
   * @param {*} tMetadata Object with same attr than tableConfig to replace at view state
   */
  const updateTableMetadata = (tMetadata = tableConfig) => {
    let table = cloneDeep(tMetadata);
    table = resizeNameTaskColumn(table, width);
    table = handleLeanStatusColPosition(table, true, leanStatusColVisibility);

    setTableConfig(table);
  };

  /**
   * This functions update the state of activities at view
   */
  const updateRender = (ac = activities) => {
    setActivities([...ac]);
  };

  /**
   * This function is used by datepickers to save new valeus to component state
   * @param {*} value Moment date object
   * @param {*} dateString String date
   */
  const changeDateState = async (value, dateString) => {
    if (dateString[0] != '' && dateString != '') {
      await setDateRange((prev) => {
        prev.start = dateString[0];
        prev.end = dateString[1];
        return prev;
      });
      await setGroupBy((prev) => {
        prev = { criteria: 'activityId', sort: 'asc' };
        return prev;
      });
      getLookahead();
    } else {
      setActivities([]);
    }
  };

  /**
   * This function handles with resize of cols inside the table from dragging resize  from header titles
   * @param {*} width Updated width catched from the event
   * @param {*} colMetadata Single column metadata that were resized
   */
  const onDivResizeHandler = (width, colMetadata) => {
    if (colMetadata.doubleClicked) {
      colMetadata.width = width;
      colMetadata.doubleClicked = false;
      updateTableMetadata();
    }
  };

  /**
   * This function handles the click, and the unclick from user when the resize is catched
   * @param {*} col Single column metadata that is going to be resized
   * @param {*} val Boolean, true for start resizing, false for end resizing
   */
  const onDivResizeDoubleClicked = (col, val) => {
    col.doubleClicked = val;
    // setResizing(val)
    // updateTableMetadata()
  };

  /**
   * This function handles the drag n drop movements for change cols disposition
   * @param {*} oldIndex Index from the array that is dragged
   * @param {*} newIndex Index from the element that the dragged el is going to be moved
   */
  const moveCard = async ({ oldIndex, newIndex }) => {
    const dragCard = tableConfig[oldIndex];

    const a = update(tableConfig, {
      $splice: [
        [oldIndex, 1],
        [newIndex, 0, dragCard]
      ]
    });
    updateTableMetadata(a);
  };

  /** handle popover of massive selection */
  const handleVisibleMassivePop = (visible, type) => {
    if (type === 'subcontract') {
      setMassiveSubcontractPop({ visible });
    } else if (type === 'lean') {
      setMassiveLeanPop({ visible });
    } else if (type === 'priority') {
      setMassivePriorityPop({ visible });
    } else if (type === 'type') {
      setMassiveTypePop({ visible });
    } else if (type === 'status') {
      setMassiveStatusPop({ visible });
    } else if (type === 'commitment') {
      setMassiveCommitmentPop({ visible });
    }
  };

  /**
   * This function handles with massive selection tasks
   * @param {*} selection Element to change selecton status by it active attribute
   * @param {*} flag This saves the parent selected status to match with their children
   * @param {*} toAdd Array with tasks to add to state array with massive selection
   * @param {*} toDelete Array with tasks to delete from state array with massive selection
   */
  const massiveSelectionHandler = (
    selection,
    flag = null,
    toAdd = [],
    toDelete = []
  ) => {
    const originalSelectionValue = selection.active;
    if (selection.hide === true) {
      return false;
    }

    /** Define array of children (activity or task) */
    const childs = selection.tasks || selection.children;

    /** Defines first flag value from calling this function without flag */
    if (flag == null) {
      flag = !selection.active;
      selection.active = !selection.active;
    }

    /** Matchs the values to dont change already setted correct value */
    if (flag != selection.active) {
      selection.active = !selection.active;
    }

    /** Defines if the current selection must be deleted or added to state array of massive selection */
    if (
      selection.editablePermissionACP &&
      !selection.editablePermissionACP.editable
    ) {
      selection.active = originalSelectionValue;
    } else if (selection.active) {
      toAdd.push(selection);
    } else if (!selection.active) {
      toDelete.push(selection);
    }

    /** Recursively repetition for children */
    if (childs) {
      if (childs.length) {
        childs.map((el) => {
          massiveSelectionHandler(el, flag, toAdd, toDelete);
        });
      }
    }
  };

  /**
   * This function handles with massive progress change
   */
  const massiveProgressHandler = async () => {
    const asyncMap = massiveSelection.map(async (selection) => {
      const { activity, parentTask } = getActivityAndParentReference(
        selection.activityId,
        selection.parent_id
      );

      /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
      const doesExistAtReference = getTask(selection.id, null, activity);
      if (doesExistAtReference.length) {
        const reference = doesExistAtReference[0];
        /** VALIDATION: Task with children cant be setted the progress */
        if (!reference.children.length) {
          reference.progress = massiveProgress;
          if (massiveProgress == 0) {
            if (calculatePonderators) {
              calculatePonderators(
                parentTask || activity,
                activity,
                updateAsyncTask
              );
            }
          }
          // taskService.update(reference)
          updateAsyncTask(reference);
          await calculateProgress(
            reference,
            parentTask || activity,
            activity,
            updateAsyncTask,
            updateAsyncActivity
          );
        }
      }
    });

    await Promise.all(asyncMap);
    handlePopVisibility(false, 'progressMassive');
    // updateRender()
  };

  const handlePopVisibility = async (visible, id) => {
    // setMassiveOnProcess(true)
    setPopsVisibility({
      ...popsVisibility,
      [id]: visible
    });
  };

  /** this function close all popover except uniqueIdConstraintDiff */
  const handlePopVisibilityCloseAll = async (uniqueIdConstraintDiff) => {
    const popArr = popsVisibility;
    Object.keys(popArr).forEach((v) => {
      if (uniqueIdConstraintDiff !== v) {
        popArr[v] = false;
      }
    });
    setPopsVisibility({
      ...popArr
    });
  };

  /**
   * This function builds a JSX to select responsables at inline edition
   */
  const renderFriend = (props, option, snapshot, className) => {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10
    };

    let toShowName = `${option.object.name} ${option.object.lastname}`;

    if (toShowName) {
      if (toShowName.length > 17) {
        toShowName = toShowName.slice(0, 17) + '...';
      }
    }

    return (
      <button
        {...props}
        className={className}
        type="button"
        title={option.object.email}>
        <span>
          <img alt="" style={imgStyle} width={20} src={option.photo} />
          <span style={{ fontSize: 15 }}>{toShowName}</span>
        </span>
      </button>
    );
  };

  const updateAsyncTask = (task) => {
    const copy = cloneDeep(task);
    delete copy.activityObject;
    taskService.update(copy);
    // socket.emit('task_update', {
    //     sector: projectState.sectorSelected,
    //     task: copy
    // })
  };

  const updateAsyncActivity = (activity) => {
    activityService.update(activity).then(() => {
      activityService.updateTreeProgress(activity.id);
      const newActivityHistorical = {
        activityId: activity.id,
        progress: activity.progress
      };
      historicalActivityProgressService.create(newActivityHistorical);
    });
    socket.emit('activity_update', {
      sector: projectState.sectorSelected,
      activity: activity
    });
  };

  /**
   * This function executes massiveSelectionHandler then sets the selection state to
   * handle massive actions like deleting, changing responsable, etc.
   * @param {*} selection Initial selection
   */
  const recursivelySelection = (selection) => {
    /** Temporal array to save added task */
    const toAdd = [];

    /** Temporal array to save deleted task */
    const toDelete = [];

    /** Execution of recursively selection */
    massiveSelectionHandler(selection, null, toAdd, toDelete);

    /** Setting state of massive actions */
    setMassiveSelection((prev) => {
      /* Take off elements of massive actions state */
      const newState = differenceBy(prev, toDelete, 'id');
      /** Then we check those elements that arent at the state and add them */
      toAdd.map((el) => {
        const doesExist = newState.find((single) => el.id == single.id);
        if (!doesExist) {
          /** Filter if it is a task (avoid adding activities) */
          if (el.children || el.activityId) {
            if (['activityId', 'no-group'].includes(groupBy.criteria)) {
              if (el.showWeeklyPlan) {
                newState.push(el);
              }
            } else {
              if (el.showWeeklyPlan) {
                if (!el.parent_id) newState.push(el);
              }
            }
          }
        }
      });

      prev = cloneDeep(newState);
      return prev;
    });
  };

  /** this function set to null subcontractId */
  const deleteSubcontract = (task, activity) => {
    const doesExistAtReference = getTask(task.id, null, activity);
    if (doesExistAtReference.length) {
      const reference = doesExistAtReference[0];
      reference.subcontractId = null;
      const copy = cloneDeep(task);
      taskService.removeSubcontract(task.id);
      socket.emit('task_update', {
        sector: projectState.sectorSelected,
        task: copy
      });
    }
    handlePopVisibility(false, 'deleteSubcontract');
  };

  const [array, setArray] = useState([]);
  useEffect(() => {
    setArray([renderGeneralHeader(), ...activities]);
  }, [activities, isLoading, tableConfig]);

  /**
   * This function render the table with activities and their tasks
   */
  const renderTable = () => {
    if (isLoading) {
      return (
        <Spin className="loader-spinner-lookahead-table" indicator={antIcon} />
      );
    }
    if (activities.length) {
      /* const array = [renderGeneralHeader(), ...activities]; */

      if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
        return (
          <CustomPaginator
            adjustHeight={195}
            totalTablesWidth={totalTablesWidth}
            massiveSelection={massiveSelection}
            resizing={resizing}
            current={currentPage}
            setCurrentPage={setCurrentPage}
            isFilterdData={existFilter}
            data={array}
            t={t}
            itemSize={height < 700 ? height * 0.55 : height * 0.68}
            weeklyplan
            renderItem={(item, key, virtualizeRef) => {
              if (key == 0) {
                paginatorRef = virtualizeRef;
                return item;
              } else if (!resizing) {
                window.rowsActivities &&
                  window.rowsActivities.resetAfterRowIndex(0);
                return (
                  <WeeklyPlanActivity
                    subtradeRole={subtradeRole}
                    t={t}
                    volatileTaskData={volatileTaskData}
                    isFilterdData={existFilter}
                    permission={permission}
                    excludeDeep={excludeDeep}
                    setTasksWeeklyPlan={setTasksWeeklyPlan}
                    getFlattenActivities={getFlattenActivities}
                    updateRender={updateRender}
                    activities={activities}
                    dateRange={dateRange}
                    updateAsyncActivity={updateAsyncActivity}
                    updateAsyncTask={updateAsyncTask}
                    ganttAPI={ganttAPI}
                    scrollStates={scrollStates}
                    setScrollStates={setScrollStates}
                    virtualizeRef={virtualizeRef}
                    resizing={resizing}
                    tableMetadata={tableConfig}
                    key={key}
                    lastLvlActivity={item}
                    resources={item.activities}
                    index={key}
                    toSelectResponsables={toSelectResponsables}
                    handleAddConstraint={handleAddConstraint}
                    haveWeeklyPlan={haveWeeklyPlan}
                    updateCommitment={updateCommitment}
                    tasksWeeklyPlan={tasksWeeklyPlan}
                    massiveSelectionHandler={recursivelySelection}
                    subContracts={subContracts}
                    lastLevelActivities={lastLevelActivities}
                    setVisibleFormSubcontract={setVisibleFormSubcontract}
                    belongsToRange={belongsToRange}
                    showDrawer={showDrawer}
                    onCloseCard={onCloseCard}
                    deleteSubcontract={deleteSubcontract}
                    setTypeResource={setTypeResource}
                    setActivityResource={setActivityResource}
                    setVisibleFormResource={setVisibleFormResource}
                    setTaskSelected={setTaskSelected}
                    calculateLeanStatus={calculateLeanStatus}
                  />
                );
              }
            }}
            perPage={1}
          />
        );
      }
      return (
        <CustomPaginator
          totalTablesWidth={totalTablesWidth}
          itemSize={height < 700 ? height * 0.55 : height * 0.68}
          current={currentPage}
          setCurrentPage={setCurrentPage}
          data={array}
          t={t}
          isFilterdData={existFilter}
          weeklyplan
          renderItem={(item, key, virtualizeRef) => {
            if (key == 0) {
              paginatorRef = virtualizeRef;
              return item;
            } else if (!resizing) {
              window.rowsActivities &&
                window.rowsActivities.resetAfterRowIndex(0);
              return (
                <LookaheadOtherGroup
                  subtradeRole={subtradeRole}
                  fromWeeklyPlan
                  t={t}
                  isFilterdData={existFilter}
                  volatileTaskData={volatileTaskData}
                  dateRange={dateRange}
                  haveWeeklyPlan={haveWeeklyPlan}
                  setTasksWeeklyPlan={setTasksWeeklyPlan}
                  getFlattenActivities={getFlattenActivities}
                  permission={permission}
                  lookaheadActions={weeklyplanActions}
                  ganttAPI={ganttAPI}
                  updateAsyncTask={updateAsyncTask}
                  updateAsyncActivity={updateAsyncActivity}
                  scrollStates={scrollStates}
                  setScrollStates={setScrollStates}
                  virtualizeRef={virtualizeRef}
                  massiveSelectionHandler={recursivelySelection}
                  setMassiveSelection={setMassiveSelection}
                  tableMetadata={tableConfig}
                  groupParent={item}
                  resources={item.activities}
                  index={key}
                  key={key}
                  groupBy={groupBy}
                  toSelectResponsables={toSelectResponsables}
                  handleAddConstraint={handleAddConstraint}
                  subContracts={subContracts}
                  showDrawer={showDrawer}
                  onCloseCard={onCloseCard}
                  lastLvlActivity={item}
                  setVisibleFormSubcontract={setVisibleFormSubcontract}
                  deleteSubcontract={deleteSubcontract}
                  lastLevelActivities={lastLevelActivities}
                  setVisibleFormResource={setVisibleFormResource}
                  setTypeResource={setTypeResource}
                  setActivityResource={setActivityResource}
                  setTaskSelected={setTaskSelected}
                  notifyRedux={() => {
                    const notifyLookaheadUpdateGroupCallback = () => {
                      dispatch(weeklyplanActions.notifyLookaheadUpdateGroup());
                    };
                    timerManager.registerAutoTimeout(
                      notifyLookaheadUpdateGroupCallback,
                      500,
                      'notifyLookaheadUpdateGroup5'
                    );
                  }}
                />
              );
            }
          }}
          perPage={1}
        />
      );
    }
    return (
      <Empty
        style={{ margin: 0 }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>{t('weekly_plan_plan.no_tasks')}</span>}
      />
    );
  };

  /**
   * This function render the header with titles for the printed tables, it allows to drag n drop the position
   * as also allow to resize the width.
   */
  const renderGeneralHeader = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Row style={{ marginLeft: 47 }}>
        <AnimatedSortable
          useDragHandle
          items={tableConfig}
          onSortEnd={moveCard}
          axis="x"
          onDivResizeHandler={onDivResizeHandler}
          onDivResizeDoubleClicked={onDivResizeDoubleClicked}
        />
      </Row>
    );
  };

  /**
   * This function search recursively inside of a array with elements with ID attr and children elements
   * @param {*} id id to find
   * @param {*} array array with children elements, and ID attr at his elements
   * @param {*} res Array to push result
   */
  const recursiveSearch = (id, array, res) => {
    array.map((el) => {
      if (el.id == id) {
        res.push(el);
      } else {
        recursiveSearch(id, el.children, res);
      }
    });
  };

  /**
   * This function search a task at state data by it ID
   * @param {*} task_id id of task to find the object
   * @param {*} taskParent parent to iterate inside it the search
   */
  const getTask = (task_id, taskParent = null, activity) => {
    let res;
    if (task_id == activity.id) {
      res = [activity];
    } else if (!taskParent) {
      res = activity.tasks.filter((el) => el.id == task_id);
    }

    if (!res.length) {
      if (!taskParent) {
        taskParent = activity.tasks;
      } else {
        taskParent = taskParent.children;
      }
      const a = [];

      recursiveSearch(task_id, taskParent, a);
      return a;
    }

    return res;
  };

  /** handle save weekly Plan */
  const saveWeeklyPlan = async (tasksWeekly) => {
    trakerServiceAppCues('Weekly Commitment');
    const weeklyPlan = tasksWeekly;
    if (weeklyPlan.length) {
      const dataWeeklyPlan = {
        commitment_tasks: weeklyPlan.length,
        week: currentWeek,
        start_date: moment(dateRange.start),
        end_date: moment(dateRange.end),
        realized_tasks: 0,
        sectorId: projectState.sectorSelected
      };
      /** bug duplicate */
      let validWeekCommitment = true;
      const weekCommitments = await weekCommitmentService.searchBySector(
        projectState.sectorSelected
      );
      if (weekCommitments.weekcommitments.length) {
        const validWeekCommitmentBd = weekCommitments.weekcommitments.filter(
          (el) =>
            el.year === yearState &&
            el.week === currentWeek &&
            el.sectorId === projectState.sectorSelected
        );
        if (validWeekCommitmentBd.length) {
          validWeekCommitment = false;
        }
      }
      if (!validWeekCommitment) {
        return false;
      }
      const loggedUser = getSignedUser();

      const currentCompany = stateCompany.currentCompany;
      const project = projectState.allProjects.find(
        (p) => p.id == projectState.projectSelected
      );

      totangoSetAccountAttributes(
        loggedUser,
        projectState.projectSelected,
        currentCompany?.name,
        currentCompany?.id,
        project?.name,
        project?.stage,
        project?.country
      );

      totangoEventTracking(
        `p_${projectState.projectSelected}`,
        loggedUser,
        'Weekly Commitment',
        'Weekly Plan'
      );

      const newWeeklyPlan = await weekCommitmentService.create(dataWeeklyPlan);
      if (newWeeklyPlan.id) {
        /** save tasks commitment */
        const syncMap = weeklyPlan.map(async (task) => {
          const user = getSignedUser();
          const dataTaskCommitment = {
            commitment_percentaje: task.commitment_percentaje || 0,
            realized_percentaje: 0,
            userId: user.id,
            weekcommitmentId: newWeeklyPlan.id,
            current_progress_task: task.progress,
            current_commitment_partial: task.current_commitment_partial || 0,
            taskId: task.id,
            is_last_level:
              !task.children
                ?.length /** last level if task not have childrens */,
            start_date: task.start_date,
            end_date: task.end_date,
            duration: task.duration,
            total_quantity: task.total_quantity,
            plan_endowment: task.plan_endowment,
            subcontractId: task.subcontractId,
            materialId: task.materialId,
            name: task.name
          };
          await taskCommitmentService.create(dataTaskCommitment);
          /** update task */
          task.lean_status = 'Committed';
          await taskService.update(task);
        });
        await Promise.all(syncMap);
        quitDismissHandler();
        const haveWeekly = await checkIfExistsWeeklyPlan(currentWeek);
        setHaveWeeklyPlan(haveWeekly);
        if (getEnvironment() === 'prod') {
          await notifyEmailCommmitment();
          dispatch(userActions.setUserUpdate());
        }
      }
    }
  };

  const notifyEmailCommmitment = async () => {
    const company = getCurrentCompany();
    const userLang = navigator.language || navigator.userLanguage;
    const users = await userService.getByProjectThrough(projectSelectedId);
    if (users.users && company) {
      const selectUsers = users.users.filter(
        (us) => us.is_active && us.userproject.report_tasks
      );
      const stageSelected = projectState.allSectors.find(
        (el) => el.id === projectState.sectorSelected
      );
      const projectSelected = projectState.allProjects.find(
        (el) => el.id === projectState.projectSelected
      );
      if (selectUsers.length) {
        const data = {
          users: selectUsers,
          endDate: String(dateRange.end),
          week: currentWeek,
          stageName: stageSelected.name || '',
          projectName: projectSelected.name || '',
          link:
            base.front +
            `${company.id}/project/${projectSelectedId}/schedule/${projectState.sectorSelected}/export/weekly/?type=commitment&planificationDay=${planification_day}&week=${currentWeek}`,
          lang: userLang.includes('es') ? 'es' : 'en',
          projectId: projectSelectedId,
          sectorId: projectState.sectorSelected
        };
        await userService.notifyCommitment(data);
      }
    }
  };

  const updateCommitment = async (task, value) => {
    const tasks = tasksWeeklyPlan;
    const findTaskIndex = tasks.findIndex((e) => e.id === task.id);
    tasks[findTaskIndex] = task;
    setTasksWeeklyPlan(tasks);
  };

  /**
   * This function render modal Add Constraint
   */
  const handleAddConstraint = (task) => {
    setVisibleForm({ value: true, tasks: [task] });
  };

  const handleUpdatedTasks = async (updatedTasks = false) => {
    if (updatedTasks) {
      if (updatedTasks.length) {
        const asyncFeature = updatedTasks.map(async (t) => {
          /** First we fetch the updated task */
          let updatedTask = await taskService.show(t.id);
          if (updatedTask) {
            updatedTask = updatedTask.task;

            /** Get activity */
            const activity = activities.find((ac) => ac.id == t.activityId);

            /** Then get the reference from state */
            const taskToUpdate = getTask(t.id, null, activity);

            /** Finally we just update the constraints array from the referenced task */
            if (taskToUpdate.length) {
              taskToUpdate[0].constraints = updatedTask.constraints;
            }
          }
        });
        await Promise.all(asyncFeature);
        updateRender();
      }
    }
  };

  const massiveSubcontractHandler = async (option) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.subcontractId = option.value;
          // taskService.update(reference)
          updateAsyncTask(reference);
        }
      });
      /** Other group by logic massive update */
    } else {
      const asyncMap = massiveSelection.map(async (selection) => {
        const referenceActivity = activities.find((el) =>
          el.tasks.find((elem) => elem.id === selection.id)
        );
        if (referenceActivity) {
          const reference = referenceActivity.tasks.find(
            (task) => task.id === selection.id
          );
          if (reference) {
            reference.subcontractId = option.value;
            reference.subcontract = subContracts.find(
              (el) => el.id === option.value
            );
            updateAsyncTask(reference);
          }
        }
      });
      await Promise.all(asyncMap);
      if (groupBy.criteria === 'subcontractId') {
        const notifyLookaheadUpdateGroupCallback = () => {
          dispatch(weeklyplanActions.notifyLookaheadUpdateGroup());
        };
        timerManager.registerAutoTimeout(
          notifyLookaheadUpdateGroupCallback,
          400,
          'notifyLookaheadUpdateGroup6'
        );
      }
    }
    handleVisibleMassivePop(false, 'subcontract');
  };

  /**
   * This function render Form Add Constraint
   */
  const renderFormConstraints = () => (
    <ConstraintForm
      t={t}
      initialTasks={visibleForm.tasks}
      visibleForm={visibleForm.value}
      setVisibleForm={(val) => setVisibleForm({ value: val, tasks: [] })}
      toSelectResponsables={toSelectResponsables}
      setConstraintAdded={(updatedTasks) => handleUpdatedTasks(updatedTasks)}
    />
  );

  const [ReloadTableUsers, setReloadTableUsers] = useState(true);
  useEffect(() => {
    if (tableConfig.length) {
      const getData = async () => {
        const subcontractsGet = await subContractService.getByProject(
          projectState.projectSelected
        );
        if (subcontractsGet) {
          const typeFrom = tableConfig.find(
            (col) => col.name == 'subcontractId'
          );
          typeFrom.from_values.splice(0, typeFrom.from_values.length);
          const subContractsOrdered = subcontractsGet.subcontracts.sort(
            dynamicSort('name')
          );
          subContractsOrdered.map((type, index) => {
            typeFrom.from_values.push({
              value: type.id,
              label: type.name,
              weigth: index + 1
            });
          });
          setTableConfig([...tableConfig]);
          setSubcontracts(subContractsOrdered);
        }
      };
      if (ReloadTableUsers) {
        getData();
      }
    }
  }, [ReloadTableUsers]);

  /**
   * This function render Form Add Constraint
   */
  const renderFormAddSubContracts = () => (
    <ModalAddSubContract
      t={t}
      Visible={visibleFormSubcontract}
      setVisible={setVisibleFormSubcontract}
      setReloadTableUsers={setReloadTableUsers}
      ReloadTableUsers={ReloadTableUsers}
    />
  );

  /**
   * This function render Form Add Constraint
   */
  const renderFormAddResources = () => (
    <ModalAddResources
      t={t}
      typeResource={typeResource}
      Visible={visibleFormResource}
      setVisible={setVisibleFormResource}
      setReloadTableUsers={setReloadTableUsers}
      activityResource={activityResource}
      setLastLevelActivities={setLastLevelActivities}
      lastLevelActivities={lastLevelActivities}
      resourceAdded={(resource) => {
        let resourceRoute;
        switch (typeResource) {
          case 'material':
            resourceRoute = 'materialId';
            break;
          case 'rrhh':
            resourceRoute = 'specialtyId';
            break;
          case 'machinery':
            resourceRoute = 'machineId';
            break;
          default:
            resourceRoute = 'materialId';
            break;
        }
        const doesExistAtReference = getTask(
          taskSelected.id,
          null,
          activityResource
        );
        if (doesExistAtReference[0]) {
          const reference = doesExistAtReference[0];
          reference[resourceRoute] = resource.id;
        }
        const saveObject = {
          ...taskSelected,
          [resourceRoute]: resource.id
        };
        updateAsyncTask(saveObject);
        const copy = cloneDeep(taskSelected);
        socket.emit('task_update', {
          sector: projectState.sectorSelected,
          task: copy
        });
      }}
      assignResourceOnCreate={false}
    />
  );

  useEffect(() => {
    const tmpYear = moment().format('YYYY');
    if (currentWeek === 1 && parseInt(prevYear) === parseInt(tmpYear)) {
      changeCurrentWeek('-0');
    }
    if (currentWeek === 1 && parseInt(prevYear) !== parseInt(tmpYear)) {
      changeCurrentWeek('+0');
    }
  }, [currentWeek]);

  const changeCurrentWeek = (add) => {
    const formatDate = 'YYYY/MM/DD';
    const week0 = parseInt(getTodayWeekWithPday(projectState));
    setPrevYear(parseInt(yearState));
    setCurrentWeekWithPday(week0);

    /** get week number */
    /** number of weeks of the year */
    const numberOfWeeks = weeksInYear(yearState);
    let weekNumber = currentWeek + parseInt(add);
    if (weekNumber > numberOfWeeks) {
      /* if (weekNumber == 53) {
                setYearState(parseInt(yearState))
            } else {
                weekNumber = weekNumber - numberOfWeeks
                setYearState(parseInt(yearState) + 1)
            } */
      weekNumber = weekNumber - numberOfWeeks;
      setYearState(parseInt(yearState) + 1);
    }
    if (weekNumber === 0) {
      const numberOfWeeks = weeksInYear(parseInt(yearState) - 1);
      weekNumber = numberOfWeeks;
      setYearState(parseInt(yearState) - 1);
    }
    setCurrentWeek(weekNumber);
    setShowInitView(true);
  };

  const renderMassiveResponsable = () => {
    const friends = [];
    const selected = [];
    const dictionary = {};

    massiveResponsableState.values.map((res) => {
      selected.push(res.email);
    });

    toSelectResponsables.map((user) => {
      if (user.is_active) {
        friends.push({
          name: user.name + ' ' + user.lastname + ' (' + user.email + ')',
          value: user.email,
          photo: user.image || fakeAvatar,
          object: user
        });
        dictionary[user.email] = user;
      }
    });

    return (
      <SelectSearch
        printOptions="always"
        className="select-search-massive select-search-massive--multiple"
        options={friends}
        value={selected}
        renderOption={renderFriend}
        onChange={(val) => {
          massiveResponsableState.values = [];
          val.map((op) => {
            massiveResponsableState.values.push(dictionary[op]);
          });
        }}
        multiple
        search
        placeholder={t('search_res_text')}
      />
    );
  };

  const quitDismissHandler = () => {
    setMassiveResponsableState({ values: [] });
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );
        activity.active = false;
        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.active = false;
          // taskService.update(reference)
          // updateAsyncTask(reference)
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            let reference = doesExistAtReference;
            reference.active = false;
            reference = cloneDeep(reference);
            // delete reference.activityObject
            // updateAsyncTask(reference)
          }
        });
      });
    }
    setMassiveSelection([]);
    handlePopVisibility(false, 'priorityMassive');
  };

  const renderExtraItemsFilter = () => (
    <div className="weekly-plan-on-filter-header">
      {haveWeeklyPlan ? (
        t('committed_text_general_')
      ) : currentWeekWithPday === currentWeek && permission == 'AC' ? (
        <Popconfirm
          title={t('sure_weekly_committed')}
          onConfirm={() => handleSaveWeeklyPlan()}
          okText={t('yes_committed')}
          cancelText={t('no_committed')}>
          <Tooltip
            title={t('committed_text_general_d')}
            visible={visibleTooltip}
            onVisibleChange={handleVisibleChange}
            onMouseEnter={handleTooltip}
            onMouseLeave={handleTooltip}
            onClick={handleTooltip}>
            <span>
              <IconComponent
                width={16}
                data={handshake}
                fill="#7DFF8A"
                className="icon-hand-shake"
              />
            </span>
          </Tooltip>
        </Popconfirm>
      ) : null}
      <Tooltip title={t('download_exportable_general')}>
        <span
          style={{ paddingLeft: 11, position: 'relative', top: -5 }}
          onClick={() =>
            props.history.push(
              '/report/week/all/' +
                currentWeek +
                '/' +
                planification_day +
                '/' +
                yearState +
                '/' +
                moment(dateRange.start).format('YYYY-MM-DD').toString() +
                '/' +
                moment(dateRange.end).format('YYYY-MM-DD').toString()
            )
          }>
          <img src={downloadWeeklyReportBtn} width={15} />
        </span>
      </Tooltip>
    </div>
  );

  const renderExtraItemsNavigationWeek = () => (
    <div className="week-navigate">
      <Row className="">
        <div className="div-week" style={{ zIndex: 100, position: 'relative' }}>
          <Col span={8} className="week-left">
            <LeftOutlined onClick={() => changeCurrentWeek(-1)} />
          </Col>
          <Col span={8}>
            {currentWeek ? (
              <span>
                <div className="week-number">
                  {t('weekly_week')} {currentWeek}
                </div>
                <div className="range-date">
                  {startCase(
                    moment(dateRange.start).format('DD MMM').replace(/\./g, '')
                  )}
                  &nbsp;-&nbsp;
                  {startCase(
                    moment(dateRange.end).format('DD MMM').replace(/\./g, '')
                  )}
                </div>
              </span>
            ) : (
              <span>
                <div className="week-number">{t('weekly_select')}</div>
                <div className="range-date">{t('weekly_init_end')}</div>
              </span>
            )}
          </Col>
          <Col span={8} className="week-right">
            <RightOutlined onClick={() => changeCurrentWeek(+1)} />
          </Col>
        </div>
      </Row>
    </div>
  );

  /**
   * This function render the header with filtering options for printed tables
   */
  const renderFilterHeader = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Row>
        <Col>
          <LookaheadFilterHeader
            renderExtraFilterRigth={renderExtraItemsFilter}
            renderExtraFilterCenter={renderExtraItemsNavigationWeek}
            defaultOrderOptions={[
              {
                name: 'activityId',
                label: t('activity_id'),
                switch: ['1 → 9', '9 → 1']
              }
            ]}
            disableLookahead
            lookaheadActions={weeklyplanActions}
            lookaheadState={'weeklyplanState'}
            groupBy={groupBy}
            modifyGroupBy={modifyGroupBy}
            data={activities}
            changeDateState={changeDateState}
            dateRange={dateRange}
            updateTableMetadata={updateTableMetadata}
            tableMetadata={tableConfig}
            resources={resources}
            setIsFilter={setExistFilter}
            subContracts={subContracts}
            toSelectResponsables={toSelectResponsables}
            updateRender={updateRender}
            lastLevelActivities={lastLevelActivities}
            haveWeeklyPlan={haveWeeklyPlan}
            filterByActivityId
          />
        </Col>
      </Row>
    );
  };

  const getWorkingDaysFromCalendar = (legacy_working_time) => {
    const ganttDhtmlxWorktime = legacy_working_time.map((workingtime) => {
      switch (workingtime) {
        case true:
          return 1;
        case false:
          return 0;
        case 1:
          return 1;
        case 0:
          return 0;
      }
    });
    return ganttDhtmlxWorktime;
  };

  const loadCalendars = (calendars) => {
    /** This method load calendars to Gantt API and also creates a custom version to use custom calculate duration */
    const customVersionCalendars = ganttAPI.loadCalendars(calendars);
  };

  useEffect(() => {
    if (calendars.length) {
      loadCalendars(calendars);
    }
  }, [calendars]);

  /**
   * This function return the reference from the state for activity and parent task
   * @param {*} activityId ID of the activity
   * @param {*} parentId ID of the parent
   * @param {*} typeSearch Type search on activities: by id, or into tasks, by selection id. Values: 'activityId', 'taskId'
   */
  const getActivityAndParentReference = (activityId, parentId) => {
    /** Get reference of the activity of current iteration */
    const activity = getActivity(activityId);
    let parentTask = null;

    /** If current iteration has a parent task, we load it */
    if (parentId) {
      parentTask = getTask(parentId, null, activity);
      if (parentTask.length) {
        parentTask = parentTask[0];
      }
    }

    return { activity, parentTask };
  };

  /**
   * This function returns the reference of an activity from state
   * @param {*} id ID of activity to get (REQUIRED)
   */
  const getActivity = (activityId) => {
    const activity = activities.find((el) => el.id == activityId);
    return activity;
  };

  /**
   * This function handle massive change of priority
   * @param {*} option Single object with selected priority
   */
  const massivePriorityHandle = async (option) => {
    let asyncMap;
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      asyncMap = massiveSelection.map(async (selection) => {
        activities.map(async (ac) => {
          const { activity, parentTask } = getActivityAndParentReference(
            selection.activityId,
            selection.parent_id
          );
          const doesExistAtReference = getTask(selection.id, null, activity);
          if (doesExistAtReference) {
            let reference = doesExistAtReference[0];
            reference.priority = option.value;
            reference = cloneDeep(reference);
            await taskService.update(reference);
          }
        });
      });
      // dispatch(weeklyplanActions.notifyLookaheadUpdateGroup())
    } else {
      massiveSelection.map(async (selection) => {
        const referenceActivity = activities.find((el) =>
          el.tasks.find((elem) => elem.id === selection.id)
        );
        if (referenceActivity) {
          const reference = referenceActivity.tasks.find(
            (task) => task.id === selection.id
          );
          if (reference) {
            reference.priority = option.value;
            updateAsyncTask(reference);
          }
        }
      });
    }
    handleVisibleMassivePop(false, 'priority');
  };

  const massiveMaterialHandler = (option) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.materialId = option.id;
          updateAsyncTask(reference);
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map(async (selection) => {
        const referenceActivity = activities.find((el) =>
          el.tasks.find((elem) => elem.id === selection.id)
        );
        if (referenceActivity) {
          const reference = referenceActivity.tasks.find(
            (task) => task.id === selection.id
          );
          if (reference) {
            reference.materialId = option.id;
            updateAsyncTask(reference);
          }
        }
      });
      if (groupBy.criteria === 'materialId') {
        const notifyLookaheadUpdateGroupCallback = () => {
          dispatch(weeklyplanActions.notifyLookaheadUpdateGroup());
        };
        timerManager.registerAutoTimeout(
          notifyLookaheadUpdateGroupCallback,
          400,
          'notifyLookaheadUpdateGroup7'
        );
      }
    }
    handlePopVisibility(false, 'materialMassive');
  };

  /** on change massive commitment percentage */
  const massiveCommitmentHandle = async () => {
    const arrExclude = [];
    const value = parseFloat(commitmentValue);
    massiveSelection.map(async (selection) => {
      activities.map(async (ac) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference) {
          const reference = doesExistAtReference[0];
          if (value <= parseFloat(reference.progress)) {
            if (!arrExclude.includes(reference.id)) {
              notifyMessage({
                title: t('not_valid_committed_title'),
                message: t('not_upper_committed'),
                type: 'error'
              });
            }
            arrExclude.push(reference.id);
          } else {
            reference.commitment_percentaje = value;
          }
        }
      });
    });
    refreshView(lastLevelActivities, haveWeeklyPlan, 'updateview');
  };

  const massiveLeanHandle = (option) => {
    const arrIdTask = [];
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );
        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          const released = reference.constraints.filter(
            (constraint) => constraint.status == 'released'
          );
          const hasConstraints = reference.constraints.length;
          const hasAllReleased =
            reference.constraints.length == released.length;
          /** First case: Has constraints but are all of them released or Has not constraints */
          if (
            reference.lean_status !== 'Committed' &&
            ((hasConstraints && hasAllReleased) || !hasConstraints)
          ) {
            reference.lean_status = option.value;
            updateAsyncTask(reference);
            arrIdTask.push(reference.id);
          }
        }
      });
    } else {
      massiveSelection.map(async (selection) => {
        const referenceActivity = activities.find((el) =>
          el.tasks.find((elem) => elem.id === selection.id)
        );
        if (referenceActivity) {
          const reference = referenceActivity.tasks.find(
            (task) => task.id === selection.id
          );
          if (reference) {
            const released = reference.constraints.filter(
              (constraint) => constraint.status == 'released'
            );
            const hasConstraints = reference.constraints.length;
            const hasAllReleased =
              reference.constraints.length == released.length;
            /** First case: Has constraints but are all of them released or Has not constraints */
            if (
              reference.lean_status !== 'Committed' &&
              ((hasConstraints && hasAllReleased) || !hasConstraints)
            ) {
              reference.lean_status = option.value;
              updateAsyncTask(reference);
              arrIdTask.push(reference.id);
            }
          }
        }
      });
    }
    // const newData = excludeDeep(activities, arrIdTask, dateRange)
    const newData = getActivitiesforWeeklyPlan(activities, dateRange);
    const newArray = lastLevelActivities;
    newArray.activities = newData;
    updateRender(newData);
    handleVisibleMassivePop(false, 'lean');
    quitDismissHandler();
  };

  /**
   * This function opens the constraint creation component with massive selection
   */
  const massiveConstraintHandler = () => {
    setVisibleForm({ value: true, tasks: massiveSelection });
  };

  /**
   * This function prints the header with actions to deal with massive selection
   */
  const renderMassiveActionHeader = () => (
    <Animated
      style={{ height: massiveSelection.length ? 30 : 0, overflow: 'hidden' }}
      animationIn="fadeIn"
      animationInDuration={250}
      animationOut="fadeOut"
      animationOutDuration={250}
      isVisible={Boolean(massiveSelection.length)}>
      <Row style={{ height: 30, backgroundColor: '#586666' }}>
        <Col span={12} offset={6} style={{ height: '100%' }}>
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            style={{ height: '100%' }}>
            {massiveOnProcess ? null : (
              <Col
                style={{
                  textAlign: 'center',
                  position: 'relative',
                  top: -3
                }}>
                {/* Selection text indicator */}
                <span style={{ color: '#7DFF8A' }}>
                  {massiveSelection.length + ' '}
                </span>
                <span style={{ color: '#FFFFFF', marginRight: 23 }}>
                  {t('lookahead_resources.masive_task')}
                  {massiveSelection.length > 1 ? 's' : null}{' '}
                  {t('lookahead_resources.masive_selected')}
                  {massiveSelection.length > 1 && t('lang') === 'es'
                    ? 's'
                    : null}
                </span>

                {/* Massive constraints */}
                <Tooltip placement="top" title={t('constraints_label_only')}>
                  <span
                    className="massive-icon-style"
                    onClick={massiveConstraintHandler}>
                    <img width={12} src={constraintMassive} />
                  </span>
                </Tooltip>
                {/* Massive start date */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        <DatePicker
                          allowClear={false}
                          className="custom-date-picker-planification"
                          style={{ left: -4 }}
                          format={'YYYY/MM/DD'}
                          placeholder={t('select_date_label_only')}
                          onChange={(date, dateString) =>
                            massiveStartHandle(dateString)
                          }
                        />
                      </span>
                    }
                    trigger="click">
                    <Tooltip placement="top" title={t('start_only_label')}>
                      <img width={12} src={datesMassive} />
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive Duration */}
                {groupBy.criteria == 'activity' ||
                groupBy.criteria == 'activityId' ? (
                  <span className="massive-icon-style">
                    <Popover
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          <div style={{ textAlign: 'center', width: 111 }}>
                            {t('tables.lookahead.plan.duration')}
                          </div>
                          <div className="div-number-progress-massive">
                            <NumberFormat
                              ref={refInputMasssiveTotal}
                              className="only-number-progress-massive"
                              defaultValue={0}
                              autoComplete="off"
                              displayType={'input'}
                              style={{ textAlign: 'center' }}
                              autoFocus
                              onFocus={(e) => e.target.select()}
                            />
                          </div>
                          <div
                            className="progress-massive-btn"
                            onClick={massiveDurationHandle}>
                            {t('apply_label')}
                          </div>
                        </span>
                      }
                      trigger="click">
                      <Tooltip
                        placement="top"
                        title={t('tables.lookahead.plan.duration')}>
                        <span>
                          <IconComponent
                            data={durationMassiveSvg}
                            width={14}
                            fill="#fff"
                            className="icon-massive-duration"
                          />
                        </span>
                      </Tooltip>
                    </Popover>
                  </span>
                ) : null}

                {/* Massive Priority */}
                <span className="massive-icon-style">
                  <Popover
                    // visible={massivePriorityPop.visible}
                    // onVisibleChange={(visible) => handleVisibleMassivePop(visible, 'priority')}
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {tableMetadata
                          .find((col) => col.name == 'priority')
                          .from_values.map((option, index) => (
                            <div
                              style={{ cursor: 'pointer' }}
                              key={index}
                              onClick={() => massivePriorityHandle(option)}>
                              <img
                                src={option.icon}
                                width={11}
                                style={{ position: 'relative', top: 0 }}
                              />
                              <span style={{ marginLeft: 5 }}>
                                {capitalize(option.label)}
                              </span>
                            </div>
                          ))}
                      </span>
                    }
                    trigger="click">
                    <Tooltip placeholder="top" title={t('priority_label_only')}>
                      <img
                        className="massive-icons"
                        width={12}
                        src={priorityMassive}
                      />
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive Progress */}
                {groupBy.criteria == 'activity' ||
                groupBy.criteria == 'activityId' ? (
                  <span className="massive-icon-style">
                    <Popover
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          <ProgressBar
                            value={massiveProgress}
                            setValue={setMassiveProgress}
                            massiveProgressHandler={massiveProgressHandler}
                            t={t}
                          />
                        </span>
                      }
                      trigger="click">
                      <Tooltip placement="top" title={t('progress_only_label')}>
                        <img width={12} src={progressMassive} />
                      </Tooltip>
                    </Popover>
                  </span>
                ) : null}

                {/* Massive Responsable */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {renderMassiveResponsable()}
                        <div
                          className="progress-massive-btn"
                          onClick={massiveResponsableHandler}>
                          {t('apply_label')}
                        </div>
                      </span>
                    }
                    trigger="click">
                    <Tooltip
                      placement="top"
                      title={t('responsable_label_only')}>
                      <img width={12} src={responsableMassive} />
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive LEAN */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    visible={massiveLeanPop.visible}
                    onVisibleChange={(visible) =>
                      handleVisibleMassivePop(visible, 'lean')
                    }
                    content={
                      <span className="progress-massive-style">
                        {tableMetadata
                          .find((col) => col.name == 'lean_status')
                          .from_values.map((option, index) => {
                            if (
                              option.value == 'Committed' ||
                              option.value == 'Restricted'
                            )
                              return null;
                            return (
                              <div
                                style={{ cursor: 'pointer' }}
                                key={index}
                                onClick={() => massiveLeanHandle(option)}>
                                <i
                                  className="fas fa-circle border-icon"
                                  style={{
                                    fontSize: 11,
                                    color: option.color,
                                    position: 'relative',
                                    top: -1
                                  }}
                                />
                                <span style={{ marginLeft: 5 }}>
                                  {capitalize(option.label)}
                                </span>
                              </div>
                            );
                          })}
                      </span>
                    }
                    trigger="click">
                    <Tooltip placement="top" title={t('lean_status_only')}>
                      <img width={12} src={leanMassive} />
                    </Tooltip>
                  </Popover>
                </span>

                {/* Subcontract */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {tableConfig.length
                          ? tableConfig
                              .find((col) => col.name == 'subcontractId')
                              .from_values.map((option, index) => (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  key={index}
                                  onClick={() =>
                                    massiveSubcontractHandler(option)
                                  }>
                                  <IconComponent
                                    data={helmet}
                                    width={15}
                                    fill={'white'}
                                    className="icon-options-subcontract"
                                  />
                                  <span style={{ marginLeft: 5 }}>
                                    {capitalize(option.label)}
                                  </span>
                                </div>
                              ))
                          : null}
                      </span>
                    }
                    trigger="click">
                    <Tooltip
                      placement="top"
                      title={t('subcontract_only_label')}>
                      <span>
                        <IconComponent
                          data={helmet}
                          width={15}
                          fill={'white'}
                          className="icon-options-subcontract"
                          style={{ position: 'relative', top: 4 }}
                        />
                      </span>
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive Commitment */}
                {groupBy.criteria == 'activity' ||
                groupBy.criteria == 'activityId' ? (
                  <span className="massive-icon-style">
                    <Popover
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          <div style={{ textAlign: 'center', width: 111 }}>
                            {t('compromise_constraint_label')}
                          </div>
                          <div className="div-number-progress-massive">
                            <NumberFormat
                              id="numberformatProjectSize"
                              name="psize"
                              className="only-number-progress-massive"
                              placeholder="%"
                              defaultValue={0}
                              autoComplete="off"
                              displayType={'input'}
                              onChange={(e) =>
                                setCommitmentValue(e.target.value)
                              }
                              isAllowed={(values) => {
                                if (values.value === '') {
                                  return true;
                                }
                                const x = parseInt(values.value);
                                if (x >= 0 && x <= 100) {
                                  return true;
                                }
                                return false;
                              }}
                            />
                            %
                          </div>

                          <div
                            className="progress-massive-btn"
                            onClick={massiveCommitmentHandle}>
                            {t('apply_label')}
                          </div>
                        </span>
                      }
                      trigger="click">
                      <Tooltip
                        placement="top"
                        title={t('committed_text_general')}>
                        <span>
                          <IconComponent
                            data={handshake}
                            fill="#fff"
                            className="icon-hand-shake-massive"
                            style={{ position: 'relative', top: 4 }}
                          />
                        </span>
                      </Tooltip>
                    </Popover>
                  </span>
                ) : null}

                {/* Massive material */}
                <MassiveTotalMaterial
                  t={t}
                  massiveSelection={massiveSelection}
                  activities={activities}
                  getTask={getTask}
                  getActivityAndParentReference={getActivityAndParentReference}
                  updateAsyncTask={updateAsyncTask}
                  handlePopVisibility={handlePopVisibility}
                  groupBy={groupBy}
                />

                {/* material massive */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {Object.keys(lastLevelActivities).length !== 0
                          ? lastLevelActivities.resource
                              .filter((el) => el.type === 'material')
                              .map((option, index) => (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  key={index}
                                  onClick={() =>
                                    massiveMaterialHandler(option)
                                  }>
                                  <IconComponent
                                    data={materialMassiveSvg}
                                    width={15}
                                    fill={'white'}
                                    className="icon-options-subcontract"
                                  />
                                  <span style={{ marginLeft: 5 }}>
                                    {capitalize(option.name)}
                                  </span>
                                </div>
                              ))
                          : null}
                      </span>
                    }
                    trigger="click">
                    <Tooltip
                      placement="top"
                      title={t('lookahead_resources.masive_material')}>
                      <span>
                        <IconComponent
                          data={materialMassiveSvg}
                          width={14}
                          fill="#fff"
                          className="icon-massive-material"
                        />
                      </span>
                    </Tooltip>
                  </Popover>
                </span>

                {/* Quit Dismiss */}
                <Tooltip placement="top" title={t('cancel_only_label')}>
                  <span
                    className="massive-icon-style"
                    onClick={quitDismissHandler}>
                    <img
                      width={12}
                      src={quitDismissIcon}
                      style={{ position: 'relative', top: -1 }}
                    />
                  </span>
                </Tooltip>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Animated>
  );

  const showDrawer = async (task, activity) => {
    setCurrentTask(task);
    setCurrentActivity(activity);
    setCardState({ visible: true });
  };

  const onCloseCard = () => {
    setPopsVisibility({});
    setCurrentTask(null);
    setCurrentActivity(null);
    setCardState({ visible: false });
  };

  /**
   * Render
   */
  const renderWithPermission = () => {
    if (permission == 'ACP' || permission == 'AC' || permission == 'V') {
      return (
        <Animated
          animationIn="fadeIn"
          animationInDuration={500}
          isVisible={true}>
          <Row className="weekly-plan">
            <Col>
              {
                <Widget
                  sectorId={stateProject.sectorSelected}
                  t={t}
                  widgetreadlweekly={
                    percentage_ops == 'percentage'
                      ? widgetreadlweekly + '%'
                      : widgetreadlweekly + selectedResource
                  }
                  widgetexpected={
                    percentage_ops == 'percentage'
                      ? widgetexpected + '%'
                      : widgetexpected + selectedResource
                  }
                  widgetplanned={
                    percentage_ops == 'percentage'
                      ? widgetplanned + '%'
                      : widgetplanned + selectedResource
                  }
                  actualPC={actualPC}
                  widgetcommitment={
                    percentage_ops == 'percentage'
                      ? widgetcommitment + '%'
                      : widgetcommitment + selectedResource
                  }
                />
                /* sectorbaselineversion ? companyStore.id != 0
                                    ?
                                    : null : null */
              }

              {renderFilterHeader()}
              {renderMassiveActionHeader()}
              {renderTable()}
              {cardState ? (
                <CardTask
                  updateRender={updateRender}
                  activities={activities}
                  currentTask={currentTask}
                  currentActivity={currentActivity}
                  cardState={cardState}
                  subContracts={subContracts}
                  tableConfig={tableConfig}
                  lastLevelActivities={lastLevelActivities}
                  onCloseCard={onCloseCard}
                  handshake={handshake}
                  toSelectResponsables={toSelectResponsables}
                  resources={resources}
                  updateAsyncTask={updateAsyncTask}
                  handlePopVisibility={handlePopVisibility}
                  popsVisibility={popsVisibility}
                  handlePopVisibilityCloseAll={handlePopVisibilityCloseAll}
                  t={t}
                  permission={permission}
                  belongsToRange={belongsToRange}
                  dateRange={dateRange}
                  setVisibleFormSubcontract={setVisibleFormSubcontract}
                  excludeDeep={excludeDeep}
                  currentWeekWithPday={currentWeekWithPday}
                  updateAsyncActivity={updateAsyncActivity}
                  updateResources={updateResources}
                  getTask={getTask}
                  deleteSubcontract={deleteSubcontract}
                  groupBy={groupBy}
                />
              ) : null}
            </Col>
          </Row>
          {/* Component Form Constraints */}
          {renderFormConstraints()}
          {renderFormAddSubContracts()}
          {renderFormAddResources()}
        </Animated>
      );
    }
    return <div>{t('non_permission_access')}</div>;
  };

  /**
   * Render
   */
  return renderWithPermission();
}

export default withTranslation()(PlanningView);
